import { OverlayTrigger, Popover } from 'react-bootstrap'
import classes from './styles.module.scss'
import PropTypes from 'prop-types'

const Overlay = ({ text, children }) => (
  <OverlayTrigger
    overlay={
      <Popover className={classes.overlay} id={text}>
        <Popover.Content>{text}</Popover.Content>
      </Popover>
    }
  >
    {children}
  </OverlayTrigger>
)

Overlay.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
}

export default Overlay
