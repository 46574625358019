import { FormattedMessage } from 'react-intl'

export const FAQ_FOR_ADMIN = [
  {
    title: 'Как добавить нового пользователя?',
    body: (
      <FormattedMessage
        id="for.admin.1"
        defaultMessage={
          'Чтобы зарегистрировать нового пользователя на главной странице, вам нужно открыть ссылку "Добавить пользователя", необходимо заполнить все поля формы и передать доступ пользователю.'
        }
      />
    ),
    open: false,
    id: 214,
  },
  {
    title: 'Как определить роль пользователя?',
    body: (
      <FormattedMessage
        id="for.admin.2"
        defaultMessage={
          'Исходя из предписаний по назначению пользователей, вы можете выбрать роль каждого на сайте KMS. Роль пользователя можно определить в процессе регистрации, выбрать в поле «роль» — модератор, администратор или юзер. Вы можете сделать это, как сразу, так и после регистрации.'
        }
      />
    ),
    open: false,
    id: 223,
  },
  {
    title: 'Роль пользователя назначена неверно, как ее изменить? ',
    body: (
      <FormattedMessage
        id="for.admin.3"
        defaultMessage={
          'Для того чтобы изменить роль пользователя, нужно открыть вкладку «Пользователи», навести на профайл, интересующего вас кандидата, справа вы увидите несколько активных «окон». Чтобы изменить роль пользователя, необходимо нажать на «Редактирование». Перед вами откроется профайл пользователя, в графе «Роль» вы можете выбрать роль юзера — назначить его пользователем, модератором или администратором.'
        }
      />
    ),
    open: false,
    id: 126,
  },
  {
    title: 'Не получается добавить пользователя',
    body: (
      <FormattedMessage
        id="for.admin.4"
        defaultMessage={
          'Если вы неоднократно пробовали добавить пользователя, но каждый раз система выдавала ошибку, нужно перезагрузить страницу, и повторить попытку. Если система дает сбой несмотря на обновление, обратитесь в службу поддержки.'
        }
      />
    ),
    open: false,
    id: 143,
  },
  {
    title: 'Как удалить пользователя?',
    body: (
      <FormattedMessage
        id="for.admin.5"
        defaultMessage={
          'Чтобы удалить пользователя, необходимо открыть вкладку «Пользователи», навести курсор мышки на профайл, интересующего вас кандидата, справа вы увидите несколько активных «окон». Чтобы удалить пользователя, необходимо нажать на «Корзину» и подтвердить действие. '
        }
      />
    ),
    open: false,
    id: 123,
  },
]

export const FAQ_FOR_MODERATOR = [
  {
    title:
      'Не могу найти интересующий меня материал по ключевому слову, что делать?',
    body: (
      <FormattedMessage
        id="for.moderator.1"
        defaultMessage={
          'Проверьте правильность написания термина, если это не помогло найти интересующий вас материал, введите новое ключевое слово.'
        }
      />
    ),
    open: false,
    id: 214,
  },
  {
    title: 'Как добавить публикацию? ',
    body: (
      <FormattedMessage
        id="for.moderator.2"
        defaultMessage={
          'Если вы хотите добавить публикацию на сайт, нужно открыть ссылку «Публикации». Вам откроется окно «Добавление публикации». Нужно выбрать тип публикации — определите к какой их сфер медицины относится ваш материал. Прикрепите файл — из вашего компьютера в форму нужно перенести документ, который будет отвечать всем запросам публикации. Вы можете разрешить другим пользователям скачивать документ, если вы не хотите этого, перетяните синий бегунок в левую сторону. Внимательно заполните форму ниже. После того как вы закончите, нажмите «Сохранить». Публикация отправится на рассмотрение модератору.'
        }
      />
    ),
    open: false,
    id: 223,
  },
  {
    title: 'Как одобрить материал на публикацию?',
    body: (
      <FormattedMessage
        id="for.moderator.3"
        defaultMessage={
          'Чтобы одобрить публикацию, модератору нужно открыть «Админ панель». При открытии «Админ панели» кликните на ссылку «Заявки на публикацию». Вам откроется список всех публикаций часть, которых не одобрены и находятся на рассмотрении. Для начала нужно ознакомиться с содержимым публикации, для этого нажмите «Просмотр». Чтобы одобрить размещение публикации на ресурсе, нужно нажать «Подтвердить». Чтобы удалить публикацию, нажмите «Отклонить».'
        }
      />
    ),
    open: false,
    id: 126,
  },
  {
    title: 'Случайно отклонил публикацию пользователя, что делать?',
    body: (
      <FormattedMessage
        id="for.moderator.4"
        defaultMessage={
          'Нужно открыть «Заявки на публикации» и кликнуть на ссылку публикации, которая вас интересует. Даже при отклонении публикация останется в архиве KMS. Вам откроется новая вкладка, в ней будет исходная отклоненная публикация. Для того, чтобы разместить ее на сайте, нужно кликнуть «Подтвердить» и публикация отобразится на сайте.'
        }
      />
    ),
    open: false,
    id: 143,
  },
  {
    title: 'Как добавить новость? ',
    body: (
      <FormattedMessage
        id="for.moderator.5"
        defaultMessage={
          'Для того, чтобы загрузить новость нужно открыть окно «Добавить новость». Внимательно заполните форму ниже. После того, как вы закончите, нажмите «Сохранить». После этого ваша статья появится на сайте KMS.'
        }
      />
    ),
    open: false,
    id: 12398,
  },
  {
    title: 'Как редактировать новость?',
    body: (
      <FormattedMessage
        id="for.moderator.6"
        defaultMessage={
          'Модератор может не только загружать новости на сайт, но также редактировать или удалять уже опубликованные материалы. Для того, чтобы внести правки в материал кликните иконку «Редактирование новости».  Вы попадете в редактуру. Внесите, интересующие вас правки, а затем нажмите «Сохранить».'
        }
      />
    ),
    open: false,
    id: 123987,
  },
  {
    title: 'Как удалить новость?',
    body: (
      <FormattedMessage
        id="for.moderator.7"
        defaultMessage={
          'Для того чтобы удалить новость, нужно открыть вкладку «Новости». И нажать иконку с корзиной. После этого на экране появится оповещение об удалении новости.'
        }
      />
    ),
    open: false,
    id: 12323223,
  },
]

export const FAQ_FOR_USER = [
  {
    title: 'Не могу найти интересующий меня материал по ключевому слову ',
    body: (
      <FormattedMessage
        id="for.user.1"
        defaultMessage={
          'Проверьте правильность написания термина, если это не помогло найти интересующий вас материал, введите новое ключевое слово.'
        }
      />
    ),
    open: false,
    id: 214,
  },
  {
    title: 'Как добавить публикацию?',
    body: (
      <FormattedMessage
        id="for.user.2"
        defaultMessage={
          'Если вы хотите добавить публикацию на сайт, нужно открыть ссылку «Публикации». Вам откроется окно «Добавление публикации». Нужно выбрать тип публикации — определите к какой их сфер медицины относится ваш материал. Прикрепите файл — из вашего компьютера в форму нужно перенести документ, который будет отвечать всем запросам публикации. Вы можете разрешить другим пользователям скачивать документ, если вы не хотите этого, перетяните синий бегунок в левую сторону. Внимательно заполните форму ниже. После того как вы закончите, нажмите «Сохранить». Публикация отправится на рассмотрение модератору.'
        }
      />
    ),
    open: false,
    id: 223,
  },
  {
    title: 'Почему мои публикации не появляются на сайте?',
    body: (
      <FormattedMessage
        id="for.user.3"
        defaultMessage={
          'Модераторы просматривают все публикации, которые загружают пользователи на сайт. Причин, по которым публикации до сих пор нет на сайте может быть две. Первая — модератор еще не проверил публикацию на актуальность и правильность изложения. Вторая — публикация не подходит под запросы KMS.'
        }
      />
    ),
    open: false,
    id: 126,
  },
  {
    title: 'Как войти в систему с другого устройства? ',
    body: (
      <FormattedMessage
        id="for.user.4"
        defaultMessage={
          'При регистрации вам выдавали логин и пароль пользователя, используя из откройте в своем браузере https://kms-web.timelysoft.org/ и заполните поля авторизации. Если вы не помните свой логин и пароль, обратитесь в службу поддержки. '
        }
      />
    ),
    open: false,
    id: 143,
  },
]
