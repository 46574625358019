import { types } from 'mobx-state-tree'
import PagedListRequestModel from '../PagedListRequestModel'

const NewsListRequestModel = PagedListRequestModel.named(
  'NewsListRequestModel',
).props({
  createdDate: types.optional(types.string, ''),
  createdByUserId: types.optional(types.string, ''),
  sortBy: types.optional(types.string, ''),
})

export default NewsListRequestModel
