import { types } from 'mobx-state-tree'

const mainPageNewsModel = types.model('mainPageNewsModel', {
  id: types.optional(types.integer, 0),
  title: types.maybeNull(types.string),
  body: types.maybeNull(types.string),
  mainFilePath: types.maybeNull(types.string),
})

export default mainPageNewsModel
