import { applySnapshot, getSnapshot, types } from 'mobx-state-tree'
import {
  UsersPagedDto,
  UserModel,
  ChangePasswordCommand,
  UserListRequestModel,
  UserEditCommand,
  UserActivityRequestModel,
  UserActivityPagedDto,
} from 'stores/models'
import { apiV1 } from 'core/requests'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'

const NewsStore = types
  .model('NewsStore')
  .props({
    _users: types.optional(UsersPagedDto, {}),
    _userDetail: types.optional(UserModel, {}),
    _activityLogs: types.optional(UserActivityPagedDto, {}),
    _isLoading: types.optional(types.boolean, false),
    _isLoadingDetail: types.optional(types.boolean, false),
  })
  .actions(self => {
    const USERS_API = '/api/users'

    const setUsers = usersData => {
      applySnapshot(self._users, usersData)
    }

    const setUserDetail = usersData => {
      applySnapshot(self._userDetail, usersData)
    }

    const setActivityLogs = activityLogsData => {
      applySnapshot(self._activityLogs, activityLogsData)
    }

    const setIsLoading = isLoading => {
      self._isLoading = isLoading
    }

    const setIsLoadingDetail = isLoading => {
      self._isLoadingDetail = isLoading
    }

    const getActivityLogs = async params => {
      const activityLogsRequest = fromPromise(
        apiV1.get(`${USERS_API}/getusersactionslogs`, {
          params: UserActivityRequestModel.create(params),
        }),
      )

      when(() =>
        activityLogsRequest.case({
          fulfilled: response => {
            setActivityLogs(response.data)
            return true
          },
        }),
      )
      return activityLogsRequest
    }

    const getUsersList = async params => {
      const usersListRequest = fromPromise(
        apiV1.get(`${USERS_API}`, {
          params: UserListRequestModel.create(params),
        }),
      )

      when(() =>
        usersListRequest.case({
          fulfilled: response => {
            setUsers(response.data)
            return true
          },
        }),
      )
      return usersListRequest
    }

    const getUserById = async id => {
      const userByIdRequest = fromPromise(apiV1.get(`${USERS_API}/${id}`))

      when(() =>
        userByIdRequest.case({
          fulfilled: response => {
            setUserDetail(response.data)
            return true
          },
        }),
      )
      return userByIdRequest
    }

    const createUser = async userData => apiV1.post(USERS_API, userData)

    const editUser = async userData =>
      apiV1.put(USERS_API, UserEditCommand.create(userData))

    const deleteUser = async id => apiV1.delete(USERS_API, { data: { id } })

    const blockUser = async id => apiV1.put(`${USERS_API}/blockuser`, { id })

    const unblockUser = async id =>
      apiV1.put(`${USERS_API}/unblockuser`, { id })

    const changeUserPassword = async userData =>
      apiV1.put(
        `${USERS_API}/changepassword`,
        ChangePasswordCommand.create(userData),
      )

    return {
      getUsersList,
      getUserById,
      createUser,
      editUser,
      deleteUser,
      blockUser,
      getActivityLogs,
      unblockUser,
      setIsLoading,
      setIsLoadingDetail,
      changeUserPassword,
      setActivityLogs,
    }
  })
  .views(self => ({
    get users() {
      return getSnapshot(self._users)
    },
    get activityLogs() {
      return getSnapshot(self._activityLogs)
    },
    get userDetail() {
      return getSnapshot(self._userDetail)
    },
    get activityLogsList() {
      return this.activityLogs.data
    },
    get activityLogsMetadata() {
      return this.activityLogs.metadata
    },
    get usersList() {
      return this.users.data
    },
    get usersMetadata() {
      return this.users.metadata
    },
    get isLoading() {
      return self._isLoading
    },
    get isLoadingDetail() {
      return self._isLoadingDetail
    },
  }))

export default NewsStore
