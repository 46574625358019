import { types } from 'mobx-state-tree'

const UserActivityModel = types.model('UserActivityModel', {
  actionType: types.optional(types.number, 0),
  action: types.maybeNull(types.string),
  actionDate: types.maybeNull(types.string),
  entityId: types.maybeNull(types.string),
  entityName: types.maybeNull(types.string),
  entityType: types.optional(types.number, 0),
  userFullName: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
})

export default UserActivityModel
