import { types } from 'mobx-state-tree'

const UserModel = types.model('UserModel', {
  id: types.optional(types.string, ''),
  email: types.maybeNull(types.string),
  organization: types.maybeNull(types.string),
  userFullName: types.maybeNull(types.string),
  blockUser: types.optional(types.boolean, false),
  systemType: types.maybeNull(types.number),
  phoneNumber: types.maybeNull(types.string),
  roleName: types.maybeNull(types.string),
})

export default UserModel
