import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import classes from './Style.module.scss'
import classnames from 'classnames'
import TagsItem from './TagsItem'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { CloseOutline } from 'react-ionicons'

export const InputTags = ({
  values = [],
  onTags,
  name,
  errorMessage,
  label,
  elementClassName,
  className,
  ...rest
}) => {
  const [terms, setTerms] = useState(values)
  const [value, setValue] = useState('')
  const [focusIndex, setFocusIndex] = useState(-1)
  const inputRef = useRef()

  useLayoutEffect(() => {
    if (terms.length === 0) {
      setFocusIndex(-1)
    }
    onTags({ values: terms, name: name })
  }, [terms.length]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTerms(values)
  }, [values])

  const onchange = event => {
    setValue(event.currentTarget.value)
  }

  const onkeyup = event => {
    const { key } = event
    const currentValue = value.trim()
    const isEndOfText = event.currentTarget.selectionEnd === currentValue.length
    const isPossibleRight =
      (isEndOfText || currentValue.length === 0) && terms.length > 0
    if (key === 'Enter' && currentValue != '') {
      event.preventDefault()
      setTerms([...terms, currentValue])
      setValue('')
      setFocusIndex(-1)
    } else if (
      (key === 'Backspace' || key === 'ArrowLeft') &&
      isPossibleRight
    ) {
      event.preventDefault()
      setFocusIndex(terms.length - 1)
    } else if (key === 'ArrowRight' && isPossibleRight) {
      event.preventDefault()
      setFocusIndex(0)
    }
  }

  const handleRemove = (index, focus) => {
    setTerms(terms.filter((_, i) => i !== index))
    if (focus) {
      setFocusIndex(Math.max(focusIndex - 1, 0))
    }
  }

  const setSelectedIndex = index => {
    if (index < terms.length && index > -1) {
      setFocusIndex(index)
    } else {
      setFocusIndex(-1)
    }
  }

  return (
    <Form.Group className="w-100 mb-0">
      {!!label && (
        <Form.Label className={classes.customLabel} htmlFor={name}>
          {label}
        </Form.Label>
      )}
      <div className="d-flex">
        <div className={`w-100 d-inline-flex flex-wrap ${classes.tagsInput}`}>
          {terms.map((item, index) => {
            const focus = focusIndex === index
            return (
              <TagsItem
                key={item + index}
                value={item}
                index={index}
                onRemove={handleRemove}
                focus={focus}
                onSelectedIndex={setSelectedIndex}
                className={elementClassName}
              />
            )
          })}
          <input
            data-testid="input-tags"
            ref={inputRef}
            type="text"
            className={classnames(
              'border-0 w-25 flex-fill input-tags',
              className,
            )}
            value={value}
            onChange={onchange}
            onKeyUp={onkeyup}
            name={name}
            {...rest}
          />
        </div>
        <div className={classes.appendCloseBtn}>
          {terms.length ? (
            <div
              onClick={() => {
                setTerms([])
              }}
            >
              <CloseOutline color={'#718096'} height="20px" width="20px" />
            </div>
          ) : null}
        </div>
      </div>
      {!!errorMessage && (
        <Form.Control.Feedback type="invalid" className="d-block">
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
InputTags.propTypes = {
  values: PropTypes.array,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  onTags: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  elementClassName: PropTypes.string,
}

export default InputTags
