import { types } from 'mobx-state-tree'
import { NewsModel } from '.'
import PagedListMetaData from '../PagedListMetaData'

const NewsPagedDto = types.model('NewsPagedDto', {
  data: types.array(NewsModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export default NewsPagedDto
