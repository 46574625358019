import { forwardRef } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { ChevronDownOutline } from 'react-ionicons'

const CustomToggle = forwardRef(({ onClick, className, name }, ref) => (
  <button
    className={`${classes['custom-toggle']} ${className}`}
    ref={ref}
    onClick={e => {
      onClick?.(e)
      e.stopPropagation()
    }}
  >
    <span className="pr-2">{name}</span>
    <ChevronDownOutline width={'17px'} height={'17px'} />
  </button>
))

CustomToggle.displayName = 'CustomToggle'

CustomToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
}

export default CustomToggle
