const white = '#ffffff'
const bsBackgroundOption = '#f8f9fa'
const bsBackgroundOptionSelected = '#eeeeee'
const bsControlTextColor = 'rgba(0, 0, 0, 0.5)'

const styles = {
  container: base => ({
    ...base,
    width: '100%',
    fontFamily: 'Segoe UI',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
  }),
  control: base => ({
    ...base,
    height: 32,
    minHeight: 32,
    backgroundColor: 'transparent',
    borderWidth: 0,
    boxShadow: 'none',
    color: bsControlTextColor,
    alignItems: 'center',
    cursor: 'pointer',
  }),
  valueContainer: base => ({
    ...base,
    borderWidth: 0,
    borderStyle: 'solid',
    borderRadius: 2,
    boxShadow: 'none',
    paddingLeft: 3,
    height: '100%',
    paddingRight: 12,
  }),
  input: base => ({
    ...base,
    color: bsControlTextColor,
  }),
  singleValue: base => ({
    ...base,
    color: bsControlTextColor,
  }),
  placeholder: (_base, state) => ({
    display: state.selectProps.menuIsOpen ? 'none' : 'inline',
    color: bsControlTextColor,
    paddingLeft: 3,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: base => ({
    ...base,
    height: '100%',
    position: 'absolute',
    right: '0',
    top: '0',
  }),
  clearIndicator: base => ({
    ...base,
    padding: 0,
    border: 'none',
    background: 'transparent',
    width: 20,
    height: '100%',
    color: bsControlTextColor,
    alignItems: 'center',
    ':hover': {
      color: bsControlTextColor,
    },
  }),
  dropdownIndicator: base => ({
    ...base,
    border: 'none',
    backgroundColor: 'transparent',
    height: 32,
    width: 23,
    color: bsControlTextColor,
    padding: '5px 2px',
  }),
  option: (base, state) => ({
    ...base,
    color: bsControlTextColor,
    cursor: 'pointer',
    backgroundColor: state.isSelected
      ? bsBackgroundOptionSelected
      : state.isFocused
      ? bsBackgroundOption
      : white,
  }),
}

export default styles
