import { types } from 'mobx-state-tree'
import CategoryModel from './CategoryModel'
import PagedListMetaData from '../PagedListMetaData'

const CategoriesPagedDto = types.model('CategoriesPagedDto', {
  data: types.array(CategoryModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export default CategoriesPagedDto
