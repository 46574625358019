import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'

//TODO: Если ничего не добавится,то избавиться от этого компонента, т.к он бесполезен
const CustomButton = ({ children, ...props }) => {
  return <Button {...props}>{children}</Button>
}

CustomButton.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
}

export default CustomButton
