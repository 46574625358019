import { types } from 'mobx-state-tree'

const ResourceTypeTemplateModel = types.model('ResourceTypeTemplateModel', {
  id: types.maybeNull(types.optional(types.number, 0)),
  typeTemplateId: types.optional(types.number, 0),
  dataTypeId: types.optional(types.number, 0),
  resourceTypeId: types.optional(types.number, 0),
  isRequired: types.boolean,
  priorityOrder: types.optional(types.number, 0),
})

export default ResourceTypeTemplateModel
