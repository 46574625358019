import { types } from 'mobx-state-tree'

const UserInfoModel = types.model('UserInfoModel', {
  userFullName: types.optional(types.string, '', [null, undefined]),
  accessToken: types.maybeNull(types.string),
  refreshToken: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
})

export default UserInfoModel
