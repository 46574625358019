import React from 'react'
import { FormattedMessage } from 'react-intl'
import Faq from './Faq'
import classes from './Style.module.scss'

function FAQPage() {
  return (
    <div>
      <h2 className="mb-4">
        <FormattedMessage id="faq" defaultMessage="FAQ" />
      </h2>
      <div>
        <Faq />
      </div>
      <div className={classes.link_container}>
        <a
          href="https://kms-api.timelysoft.org/StaticFiles/ubdefineds/KMS%20-%20%D0%A0%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE%20%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F%2002.09.2021.docx"
          download
        >
          Открыть полное руководство пользователя отдельным документом
        </a>
      </div>
    </div>
  )
}

export default FAQPage
