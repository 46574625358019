import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import classes from './Input.module.scss'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

const InputPhone = ({ label, name, errorMessage, value, ...props }) => {
  return (
    <Form.Group className="mb-1">
      {!!label && (
        <Form.Label className={classes.customLabel} htmlFor={name}>
          {label}
        </Form.Label>
      )}
      <PhoneInput
        name={name}
        id={label}
        inputClass={`${classes.phoneInput} ${errorMessage && classes.invalid}`}
        onlyCountries={['kg', 'kz', 'ru']}
        country="kg"
        inputProps={{ maxLength: value.slice(0, 3) === '996' ? 16 : 18 }}
        value={value}
        countryCodeEditable={false}
        {...props}
      />
      {!!errorMessage && (
        <Form.Control.Feedback type="invalid" className="d-block">
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

InputPhone.propTypes = {
  register: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  isInvalid: PropTypes.bool,
  value: PropTypes.string,
}

export default InputPhone
