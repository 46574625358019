import { applySnapshot, getSnapshot, types } from 'mobx-state-tree'
import {
  ResourcePagedDto,
  ResourceModel,
  ResourceListRequestModel,
  FavoriteResourceListRequestModel,
  MainPageDataModel,
  SearchResourceModel,
  ResourceListByUserTagIdRequestModel,
} from 'stores/models'
import { apiV1 } from 'core/requests'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import axios from 'axios'

export let controller = null

const ResourcesStore = types
  .model('ResourcesStore')
  .props({
    _resources: types.optional(ResourcePagedDto, {}),
    _resourceDetail: types.optional(ResourceModel, {}),
    _isLoading: types.optional(types.boolean, false),
    _isLoadingDetail: types.optional(types.boolean, false),
    _mainPageData: types.optional(MainPageDataModel, {}),
    _resourceByTag: types.optional(ResourcePagedDto, {}),
  })
  .actions(self => {
    const RESOURCE_API = '/api/resource'

    const setResources = resourcesData => {
      applySnapshot(self._resources, resourcesData)
    }

    const setResourceDetail = resourcesData => {
      applySnapshot(self._resourceDetail, resourcesData)
    }

    const setIsLoading = isLoading => {
      self._isLoading = isLoading
    }

    const setIsLoadingDetail = isLoading => {
      self._isLoadingDetail = isLoading
    }

    const setMainPageData = data => {
      applySnapshot(self._mainPageData, data)
    }

    const getResourcesList = async params => {
      const resourcesListRequest = fromPromise(
        apiV1.get(`${RESOURCE_API}`, {
          params: ResourceListRequestModel.create(params),
        }),
      )

      when(() =>
        resourcesListRequest.case({
          fulfilled: response => {
            setResources(response.data)
            return true
          },
        }),
      )
      return resourcesListRequest
    }

    const getRequestResources = async params => {
      const resourcesListRequest = fromPromise(
        apiV1.get(`${RESOURCE_API}/getrequestresources`, {
          params: ResourceListRequestModel.create(params),
        }),
      )

      when(() =>
        resourcesListRequest.case({
          fulfilled: response => {
            setResources(response.data)
            return true
          },
        }),
      )
      return resourcesListRequest
    }

    const getRequestUserResources = async params => {
      const userResourcesListRequest = fromPromise(
        apiV1.get(`${RESOURCE_API}/getuserrequestresources`, {
          params: ResourceListRequestModel.create(params),
        }),
      )

      when(() =>
        userResourcesListRequest.case({
          fulfilled: response => {
            setResources(response.data)
            return true
          },
        }),
      )
      return userResourcesListRequest
    }

    const getResourcesListByTag = async (params, config) => {
      const resourcesListRequest = fromPromise(
        apiV1.get(`${RESOURCE_API}/getresourcesbytagid`, {
          params: ResourceListRequestModel.create(params),
          cancelToken: config?.cancelToken,
        }),
      )

      when(() =>
        resourcesListRequest.case({
          fulfilled: response => {
            setResources(response.data)
            return true
          },
        }),
      )
      return resourcesListRequest
    }

    const getResourcesListByUserTag = async params => {
      const resourcesListRequest = fromPromise(
        apiV1.get(`${RESOURCE_API}/getresourcesbyusertagid`, {
          params: ResourceListByUserTagIdRequestModel.create(params),
        }),
      )

      when(() =>
        resourcesListRequest.case({
          fulfilled: response => {
            setResources(response.data)
            return true
          },
        }),
      )
      return resourcesListRequest
    }

    const getMainPageData = async () => {
      const resourcesListRequest = fromPromise(
        apiV1.get(`${RESOURCE_API}/getallcollections`),
      )

      when(() =>
        resourcesListRequest.case({
          fulfilled: response => {
            setMainPageData(response.data)
            return true
          },
        }),
      )
      return resourcesListRequest
    }

    const getFavoritesResourcesList = async params => {
      const resourcesListRequest = fromPromise(
        apiV1.get(`${RESOURCE_API}/getuserfavoritesresources`, {
          params: FavoriteResourceListRequestModel.create(params),
        }),
      )

      when(() =>
        resourcesListRequest.case({
          fulfilled: response => {
            setResources(response.data)
            return true
          },
        }),
      )
      return resourcesListRequest
    }

    const getResourceDetail = async id => {
      const resourceDetailRequest = fromPromise(
        apiV1.get(`${RESOURCE_API}/${id}`),
      )

      when(() =>
        resourceDetailRequest.case({
          fulfilled: response => {
            setResourceDetail(response.data)
            return true
          },
        }),
      )
      return resourceDetailRequest
    }

    const searchResource = async params => {
      const resourcesSearchRequest = fromPromise(
        apiV1.get(`${RESOURCE_API}/searchbycriteria`, {
          params: SearchResourceModel.create(params),
        }),
      )

      when(() =>
        resourcesSearchRequest.case({
          fulfilled: response => {
            setResources(response.data)
            return true
          },
        }),
      )
      return resourcesSearchRequest
    }

    const checkResource = async userId => {
      const request = fromPromise(
        apiV1.get(`${RESOURCE_API}/checkresource`, {
          params: userId,
        }),
      )

      return request
    }

    const createResource = async resourceData =>
      apiV1.post(RESOURCE_API, resourceData)

    const attachResourceFile = async resourceFiles => {
      controller = axios.CancelToken.source()
      const cancelToken = controller.token
      return apiV1.put(`${RESOURCE_API}/attachfiles`, resourceFiles, {
        cancelToken,
      })
    }

    const editResource = async resourceData =>
      apiV1.put(RESOURCE_API, resourceData)

    const deleteResource = async id => apiV1.delete(RESOURCE_API, { data: id })

    const resourceFileStream = async fileId =>
      apiV1
        .get(`${RESOURCE_API}/getfilestream`, {
          params: { fileId },
          responseType: 'blob',
        })
        .then(res => {
          apiV1.post(`${RESOURCE_API}/upresourcefilesavecounter`, {
            resourceFileId: fileId,
          })
          return res.data
        })

    const addResourceToFavorite = async resourceData =>
      apiV1.post(`${RESOURCE_API}/addresourcetofavorites`, resourceData)

    const removeResourceOfFavorites = async resourceData =>
      apiV1.delete(`${RESOURCE_API}/removeresourceoffavorites`, {
        data: resourceData,
      })

    const deleteComment = async id => {
      return apiV1.delete(`${RESOURCE_API}/deletecommentfromresource`, {
        data: { id },
      })
    }

    const confirmResource = async resourceData =>
      apiV1.post(`${RESOURCE_API}/confirmresource`, resourceData)

    const rejectResource = async resourceData =>
      apiV1.post(`${RESOURCE_API}/rejectresource`, resourceData)

    const fileViewOpenCounter = async resourceData => {
      apiV1.post(`${RESOURCE_API}/openedmainfilecounter`, resourceData)
    }

    const addComment = async commentData =>
      apiV1.post(`${RESOURCE_API}/sendcommenttoresource`, commentData)

    return {
      getResourcesList,
      getResourceDetail,
      getResourcesListByTag,
      createResource,
      editResource,
      deleteResource,
      setIsLoading,
      attachResourceFile,
      setIsLoadingDetail,
      resourceFileStream,
      addResourceToFavorite,
      removeResourceOfFavorites,
      getFavoritesResourcesList,
      confirmResource,
      rejectResource,
      getMainPageData,
      searchResource,
      getResourcesListByUserTag,
      fileViewOpenCounter,
      addComment,
      deleteComment,
      checkResource,
      getRequestResources,
      getRequestUserResources,
    }
  })
  .views(self => ({
    get resources() {
      return getSnapshot(self._resources)
    },
    get resourceDetail() {
      return getSnapshot(self._resourceDetail)
    },
    get resourcesList() {
      return this.resources.data
    },
    get resourcesMetadata() {
      return this.resources.metadata
    },
    get isLoading() {
      return self._isLoading
    },
    get isLoadingDetail() {
      return self._isLoadingDetail
    },
    get mainPageData() {
      return getSnapshot(self._mainPageData)
    },
  }))

export default ResourcesStore
