import { types } from 'mobx-state-tree'
import PagedListRequestModel from '../PagedListRequestModel'

const NewsListRequestModell = PagedListRequestModel.named(
  'NewsListRequestModell',
).props({
  search: types.optional(types.string, ''),
  sortBy: types.optional(types.string, ''),
})

export default NewsListRequestModell
