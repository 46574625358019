import { Container, Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './Loader.scss'

const Loader = ({ className = '' }) => (
  <Container fluid className={`loader-container ${className}`}>
    <Spinner
      className="loader-spinner"
      animation="border"
      variant="secondary"
    />
  </Container>
)

Loader.propTypes = {
  className: PropTypes.string,
}

export default Loader
