import { types } from 'mobx-state-tree'
import PagedListRequestModel from '../PagedListRequestModel'

const SearchResourceModel = PagedListRequestModel.named(
  'SearchResourceModel',
).props({
  searchType: types.optional(types.number, 1),
  search: types.optional(types.string, ''),
  pageNumber: types.optional(types.number, 1),
  pageSize: types.optional(types.number, 10),
})

export default SearchResourceModel
