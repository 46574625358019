import { types } from 'mobx-state-tree'
import ResourceTypeTemplateModel from './ResourceTypeTemplateModel'

const ResourceTypesEditCommand = types.model('ResourceTypesEditCommand', {
  id: types.string,
  name: types.string,
  resourcesTypeTemplates: types.optional(
    types.array(ResourceTypeTemplateModel),
    [],
  ),
})

export default ResourceTypesEditCommand
