import { types } from 'mobx-state-tree'

const PagedListMetaData = types.model('PagedListMetaData', {
  pageCount: types.optional(types.number, 1),
  pageNumber: types.optional(types.number, 1),
  pageSize: types.optional(types.number, 10),
  totalItemCount: types.optional(types.number, 1),
  firstItemOnPage: types.optional(types.number, 1),
  lastItemOnPage: types.optional(types.number, 1),
  hasNextPage: types.optional(types.boolean, false),
  hasPreviousPage: types.optional(types.boolean, false),
  isFirstPage: types.optional(types.boolean, false),
  isLastPage: types.optional(types.boolean, false),
})

export default PagedListMetaData
