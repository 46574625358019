import { types } from 'mobx-state-tree'
import TagModel from './TagModel'

const CategoryModel = types.model('CategoryModel', {
  id: types.optional(types.number, 0),
  name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  parentId: types.maybeNull(types.number),
  path: types.maybeNull(types.string),
  linkedResourcesCount: types.optional(types.number, 0),
  resourceTags: types.optional(types.array(TagModel), []),
})

export default CategoryModel
