import { types } from 'mobx-state-tree'
import PagedListRequestModel from '../PagedListRequestModel'

const CategoriesListRequestModel = PagedListRequestModel.named(
  'CategoriesListRequestModel',
).props({
  search: types.optional(types.string, ''),
  sortBy: types.optional(types.string, ''),
  id: types.maybeNull(types.number),
})

export default CategoriesListRequestModel
