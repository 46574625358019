import { Form } from 'react-bootstrap'
import PasswordInput from './PasswordInput'
import classes from './Input.module.scss'
import PropTypes from 'prop-types'

const Input = ({
  label = '',
  className,
  errorMessage,
  type,
  register,
  ...props
}) => {
  return (
    <Form.Group className="mb-0 flex-grow-1">
      {!!label && (
        <Form.Label className={classes.customLabel} htmlFor={props.name}>
          {label}
        </Form.Label>
      )}
      {type === 'password' ? (
        <PasswordInput
          id={props.name}
          className={`${className} ${classes.input} ${classes.noIcon}`}
          isInvalid={!!errorMessage}
          errorMessage={errorMessage}
          register={register}
          {...props}
        />
      ) : type === 'textarea' ? (
        <>
          <Form.Control
            as="textarea"
            placeholder={label}
            className={`${classes.input} ${className}`}
            isInvalid={!!errorMessage}
            ref={register}
            id={props.name}
            {...props}
          />
          {!!errorMessage && (
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          )}
        </>
      ) : (
        <>
          <Form.Control
            placeholder={label}
            className={`${classes.input} ${className}`}
            isInvalid={!!errorMessage}
            ref={register}
            id={props.name}
            {...props}
            type={type}
          />
          {!!errorMessage && (
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          )}
        </>
      )}
    </Form.Group>
  )
}

Input.propTypes = {
  register: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onBlur: PropTypes.func,
  type: PropTypes.string,
  borderNone: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Input
