import { types } from 'mobx-state-tree'
import CommonFileModel from '../common/FileModel'

const ResourceFileModel = CommonFileModel.named('ResourceFileModel').props({
  description: types.maybeNull(types.optional(types.string, '')),
  fileType: types.maybeNull(types.optional(types.string, '')),
  fileTypeId: types.maybeNull(types.optional(types.number, 0)),
  size: types.maybeNull(types.optional(types.string, '')),
  isDownload: types.maybeNull(types.optional(types.boolean, true)),
  downloadCount: types.maybeNull(types.optional(types.number, 0)),
  openedCount: types.maybeNull(types.optional(types.number, 0)),
})

export default ResourceFileModel
