/* eslint-disable no-unused-vars */
import { useDropzone } from 'react-dropzone'
import { useCallback, useState } from 'react'
import classes from './Style.module.scss'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Row, Col } from 'react-bootstrap'
import { Button } from 'components'
import {
  TrashOutline,
  CreateOutline,
  FileTrayOutline,
  CropOutline,
} from 'react-ionicons'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/lib/ReactCrop.scss'

function dropzoneValidation(file) {
  const { type } = file
  if (
    type.includes('image') &&
    type !== 'image/jpeg' &&
    type !== 'image/jpg' &&
    type !== 'image/png' &&
    type !== 'image/svg'
  ) {
    return {
      code: 'dropzone.image.validation',
      message: `Разрешенные типы: .png, .jpg, .jpeg, .svg`,
    }
  }

  return null
}

const MainImageInput = ({ onChange, file, label }) => {
  const [crop, setCrop] = useState({ aspect: 16 / 9 })
  const [src, setSrc] = useState(null)
  const [croppedImg, setCroppedImg] = useState(null)
  const [imageRef, setImageRef] = useState(null)
  const [isCropping, setIsCropping] = useState(true)

  const onDrop = useCallback(
    file => {
      if (file.length) {
        setIsCropping(true)
        const reader = new FileReader()
        reader.addEventListener('load', () => setSrc(reader.result))
        reader.readAsDataURL(file[0])
        onChange(
          Object.assign(file[0], {
            preview: URL.createObjectURL(file[0]),
          }),
        )
      }
    },
    [onChange],
  )
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: 'image/*',
    multiple: false,
    noDrag: false,
    onDrop,
    validator: file => dropzoneValidation(file),
  })

  const onDelete = useCallback(() => {
    onChange({})
    setIsCropping(true)
  }, [onChange])

  const handleImageLoaded = image => {
    setImageRef(image)
    setCrop({ unit: '%', width: 100, aspect: 16 / 9 })
  }

  const handleOnCropChange = crop => {
    imageRef ? setCrop(crop) : null
  }

  const handleOnCropComplete = async crop => {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(
        imageRef,
        crop,
        `${new Date().getTime()}${file.name}`,
      )
      setCroppedImg(croppedImage)
    }
  }

  const handleCropClick = () => {
    setIsCropping(false)
    onChange(croppedImg ?? {})
    setCroppedImg(null)
    setCrop({ unit: '%', width: 100, aspect: 16 / 9 })
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'))
          return
        }
        const myFile = new File([blob], fileName, {
          type: blob.type,
        })
        let fileUrl = null
        fileUrl ?? window.URL.revokeObjectURL(fileUrl)
        myFile.preview = window.URL.createObjectURL(blob)
        resolve(myFile)
      }, 'image/jpeg')
    })
  }

  return (
    <div>
      {Object.keys(file).length ? (
        <>
          <aside className={classes.thumbsContainer}>
            <div className={classes.thumb} key={file.name + file.preview}>
              <div className={classes.thumbInner}>
                {isCropping && !file.smallCrop ? (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    imageStyle={{
                      objectFit: 'cover',
                      width: '100%',
                      maxHeight: '500px',
                    }}
                    onImageLoaded={handleImageLoaded}
                    onComplete={handleOnCropComplete}
                    onChange={handleOnCropChange}
                  />
                ) : (
                  <img alt={file.name} src={file.preview} />
                )}
              </div>
              <div className={classes.thumbBackdrop}>
                {isCropping && !file.smallCrop && (
                  <div className={classes.thumbIcon}>
                    <CropOutline
                      onClick={handleCropClick}
                      width="24px"
                      height="24px"
                      color={'#ECF4FF'}
                    />
                  </div>
                )}
                <label htmlFor="fileInput" className={classes.thumbIcon}>
                  <CreateOutline width="24px" height="24px" color={'#ECF4FF'} />
                  <input {...getInputProps()} id="fileInput" />
                </label>
                <div className={classes.thumbIcon}>
                  <TrashOutline
                    onClick={() => onDelete()}
                    width="24px"
                    height="24px"
                    color={'#ECF4FF'}
                  />
                </div>
              </div>
            </div>
          </aside>
        </>
      ) : (
        <>
          <div htmlFor="fileInput" className="w-100">
            <p className={classes.fileInputTitle}>{label}</p>
            <div
              className={[
                classes.dropzone,
                file.length
                  ? 'justify-content-between'
                  : 'align-items-center justify-content-center',
              ].join(' ')}
            >
              <div {...getRootProps()}>
                <input {...getInputProps()} id="fileInput" />
                <Row>
                  <Col className="text-center font-weight-normal">
                    <FileTrayOutline
                      color="#dadada"
                      width="56px"
                      height="48px"
                    />
                    <div className={classes.dropzoneDescription}>
                      <p>
                        <FormattedMessage id="publication.form.main.file.description" />
                      </p>
                    </div>
                    <Button
                      variant="outline-primary"
                      className="btn mb-3 mt-2 ml-auto mr-auto"
                    >
                      <FormattedMessage id="global.select" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </>
      )}
      {fileRejections.length ? (
        <div className="invalid-feedback d-block">
          <FormattedMessage
            id={fileRejections[0].errors[0].code}
            defaultMessage={fileRejections[0].errors[0].message}
          />
        </div>
      ) : null}
    </div>
  )
}

MainImageInput.propTypes = {
  label: PropTypes.string,
  image: PropTypes.bool,
  onChange: PropTypes.func,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default MainImageInput
