import { Link } from 'react-router-dom'
import classes from './Style.module.scss'
import { Book } from 'react-ionicons'
import { useStore } from 'hooks'

const TitleWithLogo = props => {
  const { user } = useStore('authStore')
  return (
    <Link
      to={user.role === 'Administrator' ? '/admin' : '/'}
      className={classes.TitleWithLogo}
      {...props}
    >
      <Book color="#44448e" className="mr-2" width="30px" height="25px" />
      <span className={classes['TitleWithLogo_title']}>KMS</span>
    </Link>
  )
}

export default TitleWithLogo
