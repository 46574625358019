import { types } from 'mobx-state-tree'
import ResourceCoAuthorModel from '../resources/ResourceCoAuthorModel'
import ResourceFileModel from '../resources/ResourceFileModel'
import CommonTagModel from '../common/TagModel'
import UserResourceTagModel from '../resources/UserResourceTagModel'
import ResourceValueModel from '../resources/ResourceValueModel'

const TagModel = types.model('TagModel', {
  id: types.optional(types.number, 0),
  name: types.maybeNull(types.string),
  resourceCoAuthors: types.optional(types.array(ResourceCoAuthorModel), []),
  description: types.maybeNull(types.string),
  resourceType: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  resourceTypeId: types.optional(types.number, 0),
  resourceFiles: types.optional(types.array(ResourceFileModel), []),
  resourceTags: types.optional(types.array(CommonTagModel), []),
  isConfirmed: types.maybeNull(types.boolean),
  isFavorite: types.maybeNull(types.boolean),
  userResourceTags: types.optional(types.array(UserResourceTagModel), []),
  resourcesValues: types.optional(types.array(ResourceValueModel), []),
  viewCount: types.optional(types.number, 0),
  createdByUserId: types.maybeNull(types.string),
  favoritesUsersIds: types.optional(types.array(types.string), []),
  oldFilesIds: types.optional(types.array(types.number), []),
  mainPicture: types.optional(types.array(ResourceFileModel), []),
})

export default TagModel
