import { types } from 'mobx-state-tree'
import { ResourceTypeModel } from '.'
import PagedListMetaData from '../PagedListMetaData'

const ResourceTypesPagedDto = types.model('ResourceTypesPagedDto', {
  data: types.array(ResourceTypeModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export default ResourceTypesPagedDto
