import styles from './Style.module.scss'
import { toast } from 'react-toastify'
import { SuccessIcon, WarnIcon, ErrorIcon, InfoIcon } from '../../icons'
import PropTypes from 'prop-types'
import { CloseOutline } from 'react-ionicons'

const Toast = props => {
  return (
    <>
      <div className={styles.iconWrapper}>
        {props.type === 'success' ? (
          <SuccessIcon />
        ) : props.type === 'error' ? (
          <ErrorIcon />
        ) : props.type === 'warn' ? (
          <WarnIcon />
        ) : (
          <InfoIcon />
        )}
      </div>
      <p className={styles.message}>{props.message}</p>
      <div className={styles.closeButton}></div>
    </>
  )
}

Toast.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
}
//Добавить цвета
export const sendNotification = (message, type) => {
  toast(<Toast message={message} type={type} />, {
    hideProgressBar: true,
    draggable: true,
    progress: undefined,
    closeButton: <CloseOutline height="20px" width="20px" className="ml-2" />,
    bodyClassName: styles.body,
  })
}

export default Toast
