import { lazy, Suspense, useCallback, useEffect, useState } from 'react'
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useLocation,
} from 'react-router-dom'
import { useStore } from 'hooks'
import { observer } from 'mobx-react'
import Layout from './views/Layout/index'
import routes from 'routes'
import { useIntl } from 'react-intl'
import { useBeforeLoad } from 'hooks'
import { getLocalStorage } from 'core/utils'
import ScrollToTop from './core/scrollToTop'

const Auth = lazy(() => import('./views/Auth'))
const ForgotPassword = lazy(() => import('./views/Auth/ForgotPassword'))
const RequestSuccess = lazy(() => import('./views/Auth/RequestSuccess'))
const RequestBlock = lazy(() => import('./views/Auth/RequestBlock'))
const BlockedUser = lazy(() => import('./views/Blocked'))

const App = observer(() => {
  const authStore = useStore('authStore')
  const intl = useIntl()
  const { pathname } = useLocation()
  const match = useRouteMatch(pathname)
  const [allowedRoutes, setAllowedRoutes] = useState([])

  useEffect(() => {
    const name = routes.find(route => match?.path === route.path)?.name
    if (name) document.title = intl.formatMessage(name)
  }, [match, match?.path, intl])

  const filterRoute = useCallback(
    arr => {
      return arr.reduce((acc, val) => {
        if (val.access.includes(authStore.user.role)) {
          if (val?.children && Array.isArray(val.children)) {
            acc = [
              ...acc,
              Object.assign({}, val, {
                children: filterRoute(val.children),
              }),
            ]
          } else {
            acc.push(val)
          }
        }
        return acc
      }, [])
    },
    [authStore.user.role],
  )

  useEffect(() => {
    setAllowedRoutes(filterRoute(routes))
  }, [authStore.user, filterRoute])

  const loadEventHandler = useCallback(() => {
    const session = sessionStorage.getItem('ref')

    if (!session && !authStore.isRememberMe) {
      authStore.signOut()
    }
    sessionStorage.setItem('ref', true)
  }, [authStore])

  useBeforeLoad(loadEventHandler)

  if (authStore.isBlocked)
    return (
      <Suspense fallback={null}>
        <Switch>
          {getLocalStorage('authStore')?._isStupid || (
            <Route path="/auth/signin">
              <Auth />
            </Route>
          )}
          <Route path="/blocked">
            <BlockedUser />
          </Route>
        </Switch>
        <Redirect
          to={
            !authStore.isAuth && !getLocalStorage('authStore')?._isAuth
              ? '/auth/signin'
              : '/blocked'
          }
        />
      </Suspense>
    )

  if (!authStore.isAuth && !getLocalStorage('authStore')?._isAuth)
    return (
      <Suspense fallback={null}>
        <Switch>
          {getLocalStorage('authStore')?._isStupid || (
            <Route path="/auth/signin">
              <Auth />
            </Route>
          )}
          <Route path="/auth/forgotPassword">
            <ForgotPassword />
          </Route>
          <Route path="/auth/request-success">
            <RequestSuccess />
          </Route>
          <Route path="/auth/request-block">
            <RequestBlock />
          </Route>
          <Redirect
            to={
              getLocalStorage('authStore')?._isStupid
                ? '/auth/request-block'
                : '/auth/signin'
            }
          />
        </Switch>
      </Suspense>
    )

  if (!allowedRoutes.length) return null

  return (
    <Layout routes={allowedRoutes}>
      <Suspense fallback={null}>
        <ScrollToTop>
          <Switch>
            {allowedRoutes.map(
              ({ component: Component, path, name, children }) => (
                <Route exact={!children?.length} path={path} key={name.id}>
                  <Component routes={children} />
                </Route>
              ),
            )}
            <Redirect
              to={authStore.user.role === 'Administrator' ? '/admin' : '/'}
            />
          </Switch>
        </ScrollToTop>
      </Suspense>
    </Layout>
  )
})

export default App
