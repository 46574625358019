import React from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import './Style.scss'

const ComponentsNavBar = ({ childRoutes }) => {
  return (
    <div className="ComponentsNavBar my-4">
      {childRoutes
        .filter(el => el.navigated)
        .map(({ path, name }, i) => (
          <NavLink
            to={path}
            className="ComponentsNavBar__link"
            key={i}
            isActive={(match, location) => {
              return location.state?.prevPage
                ? location.state?.prevPage === `${path}`
                : location.pathname === `${path}`
            }}
          >
            <FormattedMessage id={name.id} />
          </NavLink>
        ))}
    </div>
  )
}

ComponentsNavBar.propTypes = {
  childRoutes: PropTypes.array.isRequired,
}

export default ComponentsNavBar
