import { useEffect, useRef, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import classes from './Style.module.scss'
import { SearchOutline } from 'react-ionicons'
import { DropdownButton } from 'react-bootstrap'
import { CustomDropdownItem } from 'components'
import { SEARCH_FILTER } from 'core/constants'
import { useHistory } from 'react-router-dom'
import { useStore } from 'hooks'
import { useDebounce } from '../../hooks'
import { observer } from 'mobx-react'
import { CloseOutline } from 'react-ionicons'
import { useLocation } from 'react-router-dom'
import AsyncLocalStorage from '../../core/asyncLocalStorage'

export const useWidth = myRef => {
  const getDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight,
  })

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef])

  return dimensions
}

const useKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false)

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  })

  return keyPressed
}

const initState = {
  title: '',
  category: 1,
}

// TODO: При необходимости переделать функционал поиска
const SearchBar = observer(() => {
  const intl = useIntl()
  const [selectedFilter, setSelectedFilter] = useState(SEARCH_FILTER[0])
  const btnRef = useRef()
  const { width } = useWidth(btnRef)
  const history = useHistory()
  const [searchState, setSearchState] = useState(initState)
  const debouncedSearchTerm = useDebounce(searchState.title, 500)
  const [isShow, setisShow] = useState(false)
  const downPress = useKeyPress('ArrowDown')
  const upPress = useKeyPress('ArrowUp')
  const enterPress = useKeyPress('Enter')
  const [cursor, setCursor] = useState(0)

  const [result, setResult] = useState([])
  const resourcesStore = useStore('resourcesStore')

  const location = useLocation()
  useEffect(() => {
    if (result?.length && upPress) {
      setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState))
    }
    if (result?.length && downPress) {
      setCursor(prevState =>
        prevState < result?.length - 1 ? prevState + 1 : prevState,
      )
    }
    if (enterPress) {
      AsyncLocalStorage.setItem(
        'searchValue',
        JSON.stringify(searchState),
      ).then(() => {
        AsyncLocalStorage.getItem('searchValue').then(data =>
          setSearchState(JSON.parse(data)),
        )
      })
      if (isShow)
        history.push(
          `/results/?searchType=${searchState.category}&search=${searchState.title}`,
        )
      history.push({
        search: `?searchType=${searchState.category}&search=${searchState.title}`,
        state: {
          title: `?searchType=${searchState.category}&search=${searchState.title}`,
        },
      })
      setisShow(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upPress, enterPress, downPress])

  useEffect(() => {
    searchState.title
      ? history.push({
          search: `?searchType=${searchState.category}&search=${searchState.title}`,
          state: {
            title: `?searchType=${searchState.category}&search=${searchState.title}`,
          },
        })
      : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState])

  useEffect(() => {
    const fetchDataFromStorage = async () => {
      const data = await AsyncLocalStorage.getItem('searchValue')
      if (data) {
        setSearchState(JSON.parse(data))
        setSelectedFilter(
          SEARCH_FILTER.find(item => +item.id === +JSON.parse(data)?.category),
        )
      } else {
        setSearchState(initState)
      }
    }
    fetchDataFromStorage()
  }, [])

  useEffect(() => {
    if (result.length) {
      setSearchState({
        ...searchState,
        title: result[cursor]?.name
          ? result[cursor]?.name
          : result[cursor]?.title,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor])

  useEffect(() => {
    const { pathname, search } = location
    if (pathname && !search) {
      setSearchState(initState)
      setSelectedFilter(SEARCH_FILTER[0])
    }
  }, [location])

  async function getData() {
    setisShow(true)
    let { data } = await resourcesStore.searchResource({
      search: searchState.title,
      searchType: +searchState.category,
    })

    if (data.news && data.resources) {
      setResult([...data?.news?.data, ...data?.resources?.data])
    } else {
      setResult(
        data.news
          ? [...data?.news?.data]
          : data.resources
          ? [...data?.resources?.data]
          : [],
      )
    }
  }

  useEffect(() => {
    if (debouncedSearchTerm?.trim()) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  return (
    <>
      <div className={classes['search-bar']}>
        <label htmlFor="search-input" className={classes['search-bar_input']}>
          <SearchOutline height="25px" width="25px" color="#718096" />
          <div className="w-100">
            <input
              type="text"
              id="search-input"
              autoComplete="off"
              value={searchState.title}
              placeholder={intl.formatMessage({
                id: 'search.bar.placeholder',
                defaultMessage: 'Поиск в KMS',
              })}
              onChange={e =>
                setSearchState({ ...searchState, title: e.target.value })
              }
            />
            {debouncedSearchTerm &&
              isShow &&
              selectedFilter.id != 7 &&
              selectedFilter.id != 8 && (
                <>
                  {result?.length ? (
                    <div>
                      {result?.map((item, i) => (
                        <div
                          key={item.id}
                          className={`item ${
                            i === cursor ? classes['active'] : ''
                          }`}
                          onClick={() => {
                            history.push(
                              `/results/?searchType=${searchState.category}&search=${searchState.title}`,
                            )
                            setisShow(false)
                          }}
                        >
                          {item.name || item.title}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </>
              )}
          </div>
          {searchState.title && (
            <CloseOutline
              style={{ cursor: 'pointer' }}
              className="mr-2"
              onClick={() => setSearchState(initState)}
            />
          )}
        </label>
        <div className={classes['search-bar_chkbox']} ref={btnRef}>
          <DropdownButton
            id="dropdown-item-button"
            title={intl.formatMessage({
              id: selectedFilter.name,
              defaultMessage: 'Все результаты',
            })}
            className={classes.search_btn}
            onSelect={e => {
              setSelectedFilter(SEARCH_FILTER.find(item => +item.id === +e))
              setSearchState({ ...searchState, category: e })
            }}
          >
            <div style={{ minWidth: `${width}px` }}>
              {SEARCH_FILTER.map(el => (
                <CustomDropdownItem eventKey={el.id} key={el.id}>
                  <FormattedMessage id={el.name} />
                </CustomDropdownItem>
              ))}
            </div>
          </DropdownButton>
        </div>
      </div>
    </>
  )
})

export default SearchBar
