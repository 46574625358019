import { types } from 'mobx-state-tree'

const UserEditCommand = types.model('UserEditCommand', {
  id: types.string,
  userFullName: types.string,
  email: types.string,
  organization: types.string,
  phoneNumber: types.string,
  roleName: types.number,
})

export default UserEditCommand
