import { Resizable } from 'react-resizable'
import PropTypes from 'prop-types'

const ResizableHeaderCell = ({
  width,
  height = 10,
  onResize,
  minConstraints = [70, 10],
  maxConstraints = [400, 10],
  axis = 'x',
  ...props
}) => {
  if (!width) return <th {...props} />
  if (typeof width === 'string')
    return (
      <Resizable
        className="resizable-none-handles"
        width={+width}
        height={height}
        axis="none"
      >
        <th {...props} />
      </Resizable>
    )

  return (
    <Resizable
      className="resizable-hover-handles"
      width={width}
      height={height}
      onResize={onResize}
      axis={axis}
      maxConstraints={maxConstraints}
      minConstraints={minConstraints}
    >
      <th {...props} />
    </Resizable>
  )
}

ResizableHeaderCell.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onResize: PropTypes.func,
  minConstraints: PropTypes.array,
  maxConstraints: PropTypes.array,
  axis: PropTypes.string,
}

export default ResizableHeaderCell
