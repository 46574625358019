import { DETAIL_NEWS_ROUTE } from 'routes'

export const PASSWORD_REGEXP =
  /(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]){6,32}/g

// Previous PHONE_REGEXP: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const PHONE_REGEXP = /^([0-9]{2,3})[0-9]{3}[0-9]{3}[0-9]{3}$/

export const pageSizes = [10, 25, 50, 100]

export const SEARCH_FILTER = [
  { id: 1, name: 'search.filter.all', value: 'all' },
  { id: 2, name: 'search.filter.author', value: 'author' },
  { id: 3, name: 'search.filter.publicate', value: 'publicate' },
  { id: 4, name: 'search.filter.news', value: 'news' },
  { id: 5, name: 'search.filter.image', value: 'image' },
  { id: 6, name: 'search.filter.video', value: 'video' },
  { id: 7, name: 'search.filter.categories', value: 'category' },
  { id: 8, name: 'search.filter.tags', value: 'tags' },
]

export const TEMPLATE_FIELD_TYPES = [
  { id: 1, label: 'Строка' },
  { id: 2, label: 'Число' },
  { id: 3, label: 'Дата' },
]

export const TEMPLATE_TRANSLATION = {
  1: {
    label: 'template.article',
    placeholder: 'template.article.placeholder',
    name: 'article',
  },
  2: {
    label: 'template.note',
    placeholder: 'template.note.placeholder',
    name: 'note',
  },
  3: {
    label: 'template.language',
    placeholder: 'template.language.placeholder',
    name: 'language',
  },
  4: {
    label: 'template.link',
    placeholder: 'template.link.placeholder',
    name: 'link',
  },
  5: {
    label: 'template.review',
    placeholder: 'template.review.placeholder',
    name: 'review',
  },
  6: {
    label: 'template.documentNumber',
    placeholder: 'template.documentNumber.placeholder',
    name: 'documentNumber',
  },
  7: {
    label: 'template.issn',
    placeholder: 'template.issn.placeholder',
    name: 'issn',
  },
  8: {
    label: 'template.resourceName',
    placeholder: 'template.resourceName.placeholder',
    name: 'resourceName',
  },
  9: {
    label: 'template.resourceDescription',
    placeholder: 'template.resource.description.placeholder',
    name: 'resourceDescription',
  },
  10: {
    label: 'template.isbn',
    placeholder: 'template.isbn.placeholder',
    name: 'isbn',
  },
  11: {
    label: 'template.publisher',
    placeholder: 'template.publisher.placeholder',
    name: 'publisher',
  },
  12: {
    label: 'template.title',
    placeholder: 'template.title.placeholder',
    name: 'title',
  },
  13: {
    label: 'template.tom',
    placeholder: 'template.tom.placeholder',
    name: 'tom',
  },
  14: {
    label: 'template.published.date',
    placeholder: 'template.published.date.placeholder',
    name: 'publishedDate',
  },
  15: {
    label: 'template.author',
    placeholder: 'template.author.placeholder',
    name: 'author',
  },
  16: {
    label: 'template.pages',
    placeholder: 'template.pages.placeholder',
    name: 'pages',
  },
  17: {
    label: 'template.userTags',
    placeholder: 'template.userTags.placeholder',
    name: 'userTags',
  },
  18: {
    label: 'template.tags',
    placeholder: 'template.tags.placeholder',
    name: 'tags',
  },
}

export const ACTION_TYPE_FILTER = {
  1: [
    { id: '6', label: 'filter.news.created' },
    { id: '7', label: 'filter.news.updated' },
    { id: '8', label: 'filter.news.deleted' },
  ],
  2: [
    { id: '1', label: 'filter.resource.created' },
    { id: '2', label: 'filter.resource.updated' },
    { id: '3', label: 'filter.resource.deleted' },
    { id: '4', label: 'filter.resource.approved' },
    { id: '5', label: 'filter.resource.rejected' },
    { id: '17', label: 'captcha.addcommenttoresource' },
    { id: '18', label: 'captcha.deletecommentfromresource' },
  ],
  3: [
    { id: '9', label: 'filter.user.registered' },
    { id: '10', label: 'filter.user.created' },
    { id: '11', label: 'filter.user.edited' },
    { id: '12', label: 'filter.user.blocked' },
    { id: '13', label: 'filter.user.deleted' },
    { id: '14', label: 'filter.user.unblocked' },
    { id: '15', label: 'captcha.enteredcaptcha' },
    { id: '16', label: 'captcha.enteredincorrectcaptcha' },
  ],
  4: [
    { id: '19', label: 'resource.typecreated' },
    { id: '20', label: 'resource.typeupdated' },
    { id: '21', label: 'resource.typedeleted' },
  ],
  5: [
    { id: '22', label: 'category.created' },
    { id: '23', label: 'category.updated' },
    { id: '24', label: 'category.deleted' },
  ],
}

export const MODULE_TYPE_FILTER = [
  { id: '1', label: 'search.filter.news' },
  { id: '2', label: 'search.filter.publicate' },
  { id: '3', label: 'slider.users.title' },
  { id: '4', label: 'module.constructor' },
  { id: '5', label: 'publication.form.categories' },
]

export const ACTION_TYPE_PATH = {
  6: DETAIL_NEWS_ROUTE,
  7: DETAIL_NEWS_ROUTE,
}

export const PUBLICATIONS_TYPE_FILTER = [
  { id: '1', label: 'filter.publications.new' },
  { id: '2', label: 'filter.publications.downloadable' },
  { id: '3', label: 'filter.publications.viewed' },
  { id: '4', label: 'filter.publications.pOfActiveUsers' },
  { id: '5', label: 'filter.publications.mostComment' },
]

//Filter key
export const ACTIVITY_LIST_FILTERS_KEY = 'ACTIVITY_LIST_FILTERS_KEY'
export const LIBRARY_PUBLICATION_FILTER_KEY = 'LIBRARY_PUBLICATION_FILTER_KEY'
export const PUBLICATIONS_FILTER_KEY = 'PUBLICATIONS_FILTER_KEY'

//Table key
export const USERS_ACTIVITY_TABLE_COLUMNS_SIZES_KEY =
  'USERS_ACTIVITY_TABLE_COLUMNS_SIZES_KEY'
export const USERS_TABLE_COLUMNS_SIZES_KEY = 'USERS_TABLE_COLUMNS_SIZES_KEY'
export const RESOURCE_TYPE_TABLE_COLUMNS_SIZES_KEY =
  'RESOURCE_TYPE_TABLE_COLUMNS_SIZES_KEY'
export const PUBLICATION_REQUEST_TABLE_COLUMNS_SIZES_KEY =
  'PUBLICATION_REQUEST_TABLE_COLUMNS_SIZES_KEY'
export const ADMIN_PUBLICATION_REQUEST_TABLE_COLUMNS_SIZES_KEY =
  'ADMIN_PUBLICATION_REQUEST_TABLE_COLUMNS_SIZES_KEY'

//Sorting key
export const USERS_EHEALTH_SORTING_KEY = 'USERS_EHEALTH_SORTING_KEY'
export const USERS_LOCAL_SORTING_KEY = 'USERS_LOCAL_SORTING_KEY'
export const USERS_ACTIVITY_SORTING_KEY = 'USERS_ACTIVITY_SORTING_KEY'
export const PUBLICATION_REQUEST_SORTING_KEY = 'PUBLICATION_REQUEST_SORTING_KEY'
export const ADMIN_PUBLICATION_REQUEST_SORTING_KEY =
  'ADMIN_PUBLICATION_REQUEST_SORTING_KEY'
export const CATEGORIEST_NAME_SORTING_KEY = 'CATEGORIEST_NAME_SORTING_KEY'

//Pagination key
export const USERS_EHEALTH_PAGINATION_KEY = 'USERS_EHEALTH_PAGINATION_KEY'
export const USERS_LOCAL_PAGINATION_KEY = 'USERS_LOCAL_PAGINATION_KEY'
export const USERS_ACTIVITY_PAGINATION_KEY = 'USERS_ACTIVITY_PAGINATION_KEY'
export const PUBLICATION_TYPE_PAGINATION_KEY = 'PUBLICATION_TYPE_PAGINATION_KEY'
export const PUBLICATION_REQUEST_PAGINATION_KEY =
  'PUBLICATION_REQUEST_PAGINATION_KEY'
export const ADMIN_PUBLICATION_REQUEST_PAGINATION_KEY =
  'ADMIN_PUBLICATION_REQUEST_PAGINATION_KEY'
export const CATEGORIES_REQUEST_PAGINATION_KEY =
  'CATEGORIES_REQUEST_PAGINATION_KEY'
export const PUBLICATION_PAGINATION_KEY = 'PUBLICATION_PAGINATION_KEY'
export const MY_PUBLICATION_PAGINATION_KEY = 'MY_PUBLICATION_PAGINATION_KEY'
export const NEWS_PAGINATION_KEY = 'NEWS_PAGINATION_KEY'
export const MY_NEWS_PAGINATION_KEY = 'MY_NEWS_PAGINATION_KEY'
export const FAVORITES_PAGINATION_KEY = 'FAVORITES_PAGINATION_KEY'
export const LIBRARY_FILTERED_PUBLICATION_PAGINATION_KEY =
  'LIBRARY_FILTERED_PUBLICATION_PAGINATION_KEY'
