import SwiperCore, { Navigation, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import PropTypes from 'prop-types'
import {
  ChevronForwardCircleOutline,
  ChevronBackCircleOutline,
} from 'react-ionicons'

import './Style.scss'

SwiperCore.use([Navigation, A11y])

const Slider = ({ children, className }) => {
  return (
    <section className={`position-relative ${className}`}>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={20}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
      >
        {children.map(child => (
          <SwiperSlide key={child.key}>{child}</SwiperSlide>
        ))}
        <div className="swiper-button-prev custom-swiper-button">
          <ChevronBackCircleOutline
            color="#6881E7"
            height="25px"
            width="25px"
          />
        </div>
        <div className="swiper-button-next custom-swiper-button">
          <ChevronForwardCircleOutline
            color="#6881E7"
            height="25px"
            width="25px"
          />
        </div>
      </Swiper>
    </section>
  )
}

Slider.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  breakpoints: PropTypes.object,
}

export default Slider
