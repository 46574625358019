import { types } from 'mobx-state-tree'
import ResourceTypeTemplateModel from './ResourceTypeTemplateModel'

const ResourceTypeModel = types.model('ResourceTypeModel', {
  id: types.optional(types.number, 0),
  name: types.maybeNull(types.string),
  hasAuthor: types.maybeNull(types.boolean),
  resourcesTypeTemplates: types.optional(
    types.array(ResourceTypeTemplateModel),
    [],
  ),
})

export default ResourceTypeModel
