import { useStore } from 'hooks'
import CustomToggle from './CustomDropdownToggle'
import classes from './Style.module.scss'
import { Dropdown } from 'react-bootstrap'
import { CustomDropdownItem, Guard } from 'components'
import { FormattedMessage } from 'react-intl'
import DropdownLanguage from './DrowdownLanguage'
import { useHistory } from 'react-router-dom'

const UserInfo = () => {
  const authStore = useStore('authStore')
  const history = useHistory()
  return (
    <div className={classes.userInfo}>
      <Dropdown alignRight>
        <Dropdown.Toggle as={CustomToggle} name={authStore.user.userFullName} />
        <Dropdown.Menu className={classes.dropdownMenu}>
          <Guard allowedRoles={['User', 'Moderator']}>
            <CustomDropdownItem onClick={() => history.push('/account')}>
              <FormattedMessage id="profile.dropdown.my.profile" />
            </CustomDropdownItem>
          </Guard>
          <DropdownLanguage />
          <Dropdown.Divider className="m-0" />
          <CustomDropdownItem onClick={authStore.signOut}>
            <FormattedMessage id="profile.dropdown.exit" />
          </CustomDropdownItem>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default UserInfo
