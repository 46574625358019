import { useDropzone } from 'react-dropzone'
import { useCallback } from 'react'
import classes from './Style.module.scss'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Row, Col, Form } from 'react-bootstrap'
import { Button } from 'components'
import { TrashOutline, CreateOutline, FileTrayOutline } from 'react-ionicons'
import { DocTypeIcon, FileIcon, TiffTypeIcon, TxtTypeIcon } from '../../icons'

function dropzoneValidation(file, image) {
  const { type } = file
  if (image) {
    if (
      type.includes('image') &&
      type !== 'image/jpeg' &&
      type !== 'image/jpg' &&
      type !== 'image/png' &&
      type !== 'image/svg'
    ) {
      return {
        code: 'dropzone.image.validation',
        message: `Разрешенные типы: .png, .jpg, .jpeg, .svg`,
      }
    }
  }

  return null
}

function checkFile(_file) {
  if (_file.includes('document')) {
    return <DocTypeIcon />
  } else if (_file.includes('text')) {
    return <TxtTypeIcon />
  } else if (_file.includes('tiff')) {
    return <TiffTypeIcon />
  } else {
    return <FileIcon />
  }
}

const FileInput = ({ onChange, file, label, image = false, errorMessage }) => {
  const onDrop = useCallback(
    file => {
      onChange(
        file[0].type.includes('image')
          ? Object.assign(file[0], {
              preview: URL.createObjectURL(file[0]),
            })
          : file[0],
      )
    },
    [onChange],
  )

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: image
      ? 'image/*'
      : 'image/*, video/mp4, video/webm, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .ogg',
    multiple: false,
    noDrag: false,
    onDrop,
    validator: file => dropzoneValidation(file, image),
  })

  const onDelete = useCallback(() => {
    onChange({})
  }, [onChange])

  return (
    <div>
      {Object.keys(file).length ? (
        <>
          <aside className={classes.thumbsContainer}>
            <div className={classes.thumb} key={file.name + file.preview}>
              {file.type.includes('image') && !file.type.includes('tiff') ? (
                <div className={classes.thumbInner}>
                  <img
                    alt={file.name}
                    src={file.preview}
                    classes={classes.img}
                  />
                </div>
              ) : (
                <div className="d-flex flex-column align-items-center justify-content-between w-100">
                  <div className="mock-preview">{checkFile(file.type)}</div>
                </div>
              )}
              <div className={classes.thumbBackdrop}>
                <label htmlFor="fileInput" className={classes.thumbIcon}>
                  <CreateOutline width="24px" height="24px" color={'#ECF4FF'} />
                  <input {...getInputProps()} id="fileInput" />
                </label>
                <div className={classes.thumbIcon}>
                  <TrashOutline
                    onClick={() => onDelete()}
                    width="24px"
                    height="24px"
                    color={'#ECF4FF'}
                  />
                </div>
              </div>
            </div>
          </aside>
        </>
      ) : (
        <>
          <div htmlFor="fileInput" className="w-100">
            <p className={classes.fileInputTitle}>{label}</p>
            <div
              className={[
                classes.dropzone,
                errorMessage && classes.invalid,
                file.length
                  ? 'justify-content-between'
                  : 'align-items-center justify-content-center',
              ].join(' ')}
            >
              <div {...getRootProps()}>
                <input {...getInputProps()} id="fileInput" />
                <Row>
                  <Col className="text-center font-weight-normal">
                    <FileTrayOutline
                      color="#dadada"
                      width="56px"
                      height="48px"
                    />
                    <div className={classes.dropzoneDescription}>
                      <p>
                        <FormattedMessage id="publication.form.main.file.description" />
                      </p>
                    </div>
                    <Button
                      variant="outline-primary"
                      className="btn mb-3 mt-2 ml-auto mr-auto"
                    >
                      <FormattedMessage id="global.select" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </>
      )}
      {fileRejections.length ? (
        <div className="invalid-feedback d-block">
          <FormattedMessage
            id={fileRejections[0].errors[0].code}
            defaultMessage={fileRejections[0].errors[0].message}
          />
        </div>
      ) : null}
      {!!errorMessage && (
        <Form.Control.Feedback type="invalid" className="d-block">
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </div>
  )
}

FileInput.propTypes = {
  label: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  image: PropTypes.bool,
  onChange: PropTypes.func,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default FileInput
