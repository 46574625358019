import { useEffect } from 'react'

const useBeforeLoad = listener => {
  useEffect(() => {
    window.addEventListener('load', listener)
    return () => window.removeEventListener('load', listener)
  }, [listener])
}

export default useBeforeLoad
