import PropTypes from 'prop-types'
import classes from './Style.module.scss'
import React, { useState } from 'react'
import { ChevronDownOutline, ChevronUpOutline } from 'react-ionicons'

export const MenuItem = ({ el, selectValue, unSelectValue, choosen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const paddingPx = el.path?.split('\\').filter(el => +el > 0).length

  return (
    <li className={classes.menu_item}>
      <div
        className={classes.menu_item_row}
        style={{ paddingLeft: `${paddingPx * 15}px` }}
      >
        <div>
          <input
            type="checkbox"
            className={classes.menu_item_checkbox}
            onChange={e => {
              e.target.checked ? selectValue(el) : unSelectValue(el.id)
            }}
            checked={!!choosen.find(la => la.id === el.id)}
          ></input>
          <span>{el.title}</span>
        </div>
        {el.children?.length ? (
          <div
            className={classes.menu_item_toggle}
            onClick={() => setIsMenuOpen(prev => !prev)}
          >
            {isMenuOpen ? (
              <ChevronUpOutline color="#718096" width="16px" height="16px" />
            ) : (
              <ChevronDownOutline color="#718096" width="16px" height="16px" />
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <ul
        className={classes.menu_item_menu}
        style={{ height: isMenuOpen ? 'auto' : '0px' }}
      >
        {el.children?.length
          ? el.children.map(el => {
              return (
                <MenuItem
                  key={el.title}
                  el={el}
                  selectValue={selectValue}
                  unSelectValue={unSelectValue}
                  choosen={choosen}
                />
              )
            })
          : null}
      </ul>
    </li>
  )
}

MenuItem.propTypes = {
  el: PropTypes.object,
  selectValue: PropTypes.func,
  unSelectValue: PropTypes.func,
  choosen: PropTypes.array,
}

export default MenuItem
