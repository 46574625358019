import { types } from 'mobx-state-tree'

const CategoryTreeModel = types.model('CategoryTreeModel', {
  id: types.optional(types.number, 0),
  key: types.optional(types.number, 0),
  name: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  parentId: types.maybeNull(types.number, 0),
  path: types.maybeNull(types.string),
  priorityOrder: types.maybeNull(types.number, 0),
  children: types.maybeNull(
    types.optional(types.array(types.late(() => CategoryTreeModel)), []),
  ),
})

export default CategoryTreeModel
