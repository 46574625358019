/* eslint-disable react/display-name */
//eslint-disable-next-line
import PropTypes from 'prop-types'
import classes from './Style.module.scss'
import { useEffect, useState } from 'react'
import {
  ChevronDownOutline,
  ChevronUpOutline,
  CloseOutline,
} from 'react-ionicons'
import React from 'react'
import MenuItem from './menu_item'
import CustomMenu from './Custom_menu'

export const NestedSelect = ({
  values,
  choosen,
  setChoosen,
  invalid,
  placeholder,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    const handler = () => {
      setIsMenuOpen(false)
    }
    addEventListener('click', handler)
    return () => {
      removeEventListener('click', handler)
    }
  }, [])

  const clearAll = () => {
    setChoosen([])
  }

  const selectValue = el => {
    setChoosen(prev => [...prev, el])
  }

  const unSelectValue = id => {
    const newChoosen = choosen.filter(el => el.id !== id)
    setChoosen(newChoosen)
  }

  return (
    <div className={classes.dropdown} onClick={e => e.stopPropagation()}>
      <div>
        <div
          className={`${classes.input} ${invalid ? classes.invalid : null}`}
          onClick={() =>
            !choosen.length ? setIsMenuOpen(prev => !prev) : null
          }
        >
          {choosen.length ? (
            choosen.map(el => {
              return (
                <div key={el.id} className={classes.choosen}>
                  <span>{el.title}</span>
                  <span
                    className={classes.choosen_close}
                    onClick={() => unSelectValue(el.id)}
                  >
                    <CloseOutline height="12px" widht="12px" />
                  </span>
                </div>
              )
            })
          ) : (
            <span className={classes.placeholder}>{placeholder}</span>
          )}
        </div>

        {choosen.length ? (
          <div className={classes.clearAll} onClick={() => clearAll()}>
            <CloseOutline color="#718096" width="16px" height="16px" />
          </div>
        ) : null}

        <div
          className={classes.toggle}
          onClick={() => setIsMenuOpen(prev => !prev)}
        >
          {isMenuOpen ? (
            <ChevronUpOutline color="#718096" width="16px" height="16px" />
          ) : (
            <ChevronDownOutline color="#718096" width="16px" height="16px" />
          )}
        </div>
      </div>

      <CustomMenu
        className={`${classes.menu} ${isMenuOpen ? classes.show : null}`}
        selectValue={selectValue}
        unSelectValue={unSelectValue}
        choosen={choosen}
      >
        {values.map(el => {
          return (
            <MenuItem
              key={el.title}
              el={el}
              selectValue={selectValue}
              unSelectValue={unSelectValue}
              choosen={choosen}
            />
          )
        })}
      </CustomMenu>
    </div>
  )
}
NestedSelect.propTypes = {
  values: PropTypes.array,
  choosen: PropTypes.array,
  setChoosen: PropTypes.func,
  invalid: PropTypes.boolean,
  placeholder: PropTypes.string,
}

export default NestedSelect
