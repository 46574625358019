import { types } from 'mobx-state-tree'
import PagedListRequestModel from '../PagedListRequestModel'

const ResourceListByUserTagIdRequestModel = PagedListRequestModel.named(
  'ResourceListByUserTagIdRequestModel',
).props({
  userId: types.optional(types.string, ''),
  isConfirmed: types.maybeNull(types.boolean),
  sortBy: types.optional(types.string, ''),
  userTagId: types.maybeNull(types.number),
})

export default ResourceListByUserTagIdRequestModel
