import { types } from 'mobx-state-tree'
import ResourceModel from './ResourceModel'
import PagedListMetaData from '../PagedListMetaData'

const ResourcePagedDto = types.model('ResourcePagedDto', {
  data: types.array(ResourceModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export default ResourcePagedDto
