const white = '#ffffff'
const bsBorderRed = '#dc3545'
const bsBackgroundOption = '#f8f9fa'
const bsBackgroundOptionSelected = '#eeeeee'
const bsControlTextColor = '#1A202C'
const bsPlaceHolderColor = '#718096'

export const styles = {
  container: base => ({
    ...base,
    width: '100%',
    fontWeight: 'normal',
    fontSize: '14px',
  }),
  control: (base, state) => {
    return {
      ...base,
      minHeight: '2.9333rem',
      backgroundColor: white,
      boxShadow: 'none',
      color: bsControlTextColor,
      lineHeight: '20px',
      alignItems: 'center',
      padding: '0 16px',
      borderRadius: '2px',
      border: `1px solid ${
        state.selectProps.invalid ? bsBorderRed : '#ccd5e0'
      }`,
      ':hover': {
        border: `1px solid ${
          state.selectProps.invalid ? bsBorderRed : '#ccd5e0'
        }`,
      },
    }
  },
  valueContainer: (base, state) => ({
    ...base,
    height: '100%',
    padding: '0',
    overflow: 'visible',
    flexWrap: state.isMulti ? 'wrap' : 'nowrap',
  }),
  input: base => ({
    ...base,
    color: bsControlTextColor,
  }),
  singleValue: base => ({
    ...base,
    color: bsControlTextColor,
  }),
  multiValue: base => ({
    ...base,
    maxWidth: '275px',
  }),
  placeholder: (base, state) => ({
    display: state.selectProps.menuIsOpen ? 'none' : 'inline',
    color: bsPlaceHolderColor,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (base, state) => {
    const provided = {
      ...base,
    }

    if (state.isDisabled) {
      provided.display = 'none'
    }

    return provided
  },
  clearIndicator: (base, state) => ({
    ...base,
    padding: 0,
    border: 'none',
    background: 'transparent',
    color: state.selectProps.invalid ? bsBorderRed : bsPlaceHolderColor,
    width: 20,
    height: '100%',
    alignItems: 'center',
    ':hover': {
      color: bsControlTextColor,
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    border: 'none',
    backgroundColor: 'transparent',
    height: '2rem',
    width: 23,
    color: state.selectProps.invalid ? bsBorderRed : bsPlaceHolderColor,
    ':hover': {
      color: bsControlTextColor,
    },
    padding: '5px 2px',
  }),
  option: (base, state) => {
    const priorityOrder = state.data.priorityOrder
    return {
      ...base,
      color: bsControlTextColor,
      backgroundColor: state.isSelected
        ? bsBackgroundOptionSelected
        : state.isFocused
        ? bsBackgroundOption
        : white,
      opacity: state.isDisabled ? 0.5 : 1,
      paddingLeft: priorityOrder
        ? priorityOrder === 1
          ? 12
          : priorityOrder === 2
          ? 32
          : priorityOrder === 3
          ? 52
          : 72
        : 12,
      background: priorityOrder
        ? priorityOrder === 1
          ? 'rgb(242 242 242)'
          : priorityOrder === 2
          ? 'rgb(225 225 225)'
          : priorityOrder === 3
          ? 'rgb(205 206 205)'
          : 'rgb(190, 190, 190)'
        : null,
      fontWeight: priorityOrder ? 600 : 400,
    }
  },
  menu: base => ({
    ...base,
    padding: '0',
    margin: '0',
    borderRadius: '0',
    zIndex: '2',
  }),
}
