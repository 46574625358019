import { types } from 'mobx-state-tree'
import PagedListMetaData from '../PagedListMetaData'
import CategoryTreeModel from './CategoryTreeModel'

const CategoriesPagedDto = types.model('CategoriesTreePagedDto', {
  data: types.array(CategoryTreeModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export default CategoriesPagedDto
