import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import classes from './Style.module.scss'

const Navigation = ({ routes }) => {
  return (
    <div className={`${classes['NavHeader']} ml-lg-5`}>
      {routes.map(({ path, name, navigated }) => {
        return (
          navigated && (
            <NavLink
              to={path}
              className={classes['NavHeader__link']}
              activeClassName={classes['active']}
              key={name.id + path}
            >
              <FormattedMessage {...name} />
            </NavLink>
          )
        )
      })}
    </div>
  )
}

Navigation.propTypes = {
  routes: PropTypes.array,
}

export default Navigation
