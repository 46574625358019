import PropTypes from 'prop-types'

const Header = ({ className, children }) => {
  return (
    <header className={className}>
      <div className="header-content">{children}</div>
    </header>
  )
}
Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Header
