import { FormattedMessage } from 'react-intl'
import { Form } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { Input } from 'components'
import { sendNotification } from 'components/Toast'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useIntl } from 'react-intl'
import classes from './Style.module.scss'
import PropTypes from 'prop-types'
import { useStore } from 'hooks'

import { CrudButton } from 'components'
import { useState } from 'react'
import { useEffect } from 'react'

const Comments = ({ commentsData, resourceId, getResourceById }) => {
  const intl = useIntl()
  const resourcesStore = useStore('resourcesStore')
  const { user } = useStore('authStore')

  const [allComments, setAllComents] = useState(commentsData)
  const [allCommentsCount, setAllCommentsCount] = useState(10)
  const [isClicked, setIsClicked] = useState(false)

  const validationSchema = Yup.object().shape({
    comment: Yup.string().required(
      intl.formatMessage({
        id: 'global.validation.required',
        defaultMessage: 'Обязательно для заполнения.',
      }),
    ),
  })

  useEffect(() => {
    if (commentsData.length >= allCommentsCount) {
      setAllComents(commentsData.slice(0, allCommentsCount))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsData, allCommentsCount])

  const showAll = () => {
    if (
      commentsData.length >=
        allCommentsCount + (commentsData.length - allCommentsCount) &&
      commentsData.length - allCommentsCount >= 10
    ) {
      setAllCommentsCount(allCommentsCount + 10)
    } else {
      setAllCommentsCount(
        allCommentsCount + (commentsData.length - allCommentsCount),
      )
    }
    setIsClicked(true)
  }

  useEffect(() => {
    const loadMore = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
          document.scrollingElement.scrollHeight &&
        isClicked
      ) {
        showAll()
      }
    }

    window.addEventListener('scroll', loadMore)
    return () => {
      window.removeEventListener('scroll', loadMore)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked])

  const { handleSubmit, errors, control, register } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const handleDeleteComment = async id => {
    try {
      await resourcesStore.deleteComment(id)
      getResourceById(resourceId)
    } catch (error) {
      sendNotification(
        Object.values(error.respone?.data.errors || {})[0] || error.message,
        'error',
      )
    }
  }

  const onSubmit = async data => {
    try {
      await resourcesStore.addComment({
        comment: data.comment,
        resourceId,
      })
      getResourceById(resourceId)
    } catch (error) {
      sendNotification(
        Object.values(error.respone?.data.errors || {})[0] || error.message,
        'error',
      )
    }
  }

  return (
    <div className={`${classes.thumbsContainer} block_wrap w-100 my-3`}>
      <h3 className={classes.title}>
        <FormattedMessage
          id="publication.comments"
          defaultMessage="Коментарии"
        />
        {commentsData.length ? `(${commentsData.length})` : null}
      </h3>
      <Form onSubmit={handleSubmit(onSubmit)} className={classes.comment_form}>
        <Form.Group>
          <Controller
            name="comment"
            control={control}
            defaultValue=""
            render={({ onChange, value }) => {
              return (
                <Input
                  type="text"
                  register={register}
                  placeholder={intl.formatMessage({
                    id: 'comments.left.comment',
                    defaultMessage: 'Оставьте комментарий',
                  })}
                  name="comment"
                  onChange={onChange}
                  value={value ? value : ''}
                  errorMessage={errors.comment?.message}
                />
              )
            }}
          />
          <div
            className={`${classes.sendImage}`}
            onClick={handleSubmit(onSubmit)}
          ></div>
        </Form.Group>
      </Form>
      <div className={classes.content}>
        {allComments.length ? (
          <>
            {allComments.map((el, index) => {
              return (
                <div className={classes.withComment} key={index}>
                  <div>
                    <div className="d-flex">
                      <h4 className="mr-2 mb-2">{el.userName}</h4>
                      <span>
                        {el.createdDate.slice(0, 10)?.split('-').join('/')}
                      </span>
                    </div>
                    <p>{el.comment}</p>
                  </div>
                  {user.id === el.userId || user.role === 'Moderator' ? (
                    <div className={classes.showWhenHovered}>
                      <CrudButton
                        tooltip={intl.formatMessage({
                          id: 'comments.delete.comment',
                          defaultMessage: 'Удалить коментарий',
                        })}
                        iconType="delete"
                        onClick={() => handleDeleteComment(el.id)}
                      />
                    </div>
                  ) : null}
                </div>
              )
            })}
            {commentsData.length > 10 && !isClicked ? (
              <div onClick={() => showAll()} className={classes.showAll}>
                <FormattedMessage
                  id="publication.comments.showAll"
                  defaultMessage="Показать все"
                />
              </div>
            ) : commentsData.length > 10 ? (
              <div
                onClick={() => {
                  setIsClicked(false)
                  setAllCommentsCount(10)
                }}
                className={classes.showAll}
              >
                <FormattedMessage
                  id="library.page.filter.hide"
                  defaultMessage="скрыть"
                />
              </div>
            ) : null}
          </>
        ) : (
          <div className={classes.noComment}>
            <div className={classes.commentImg}></div>
            <p>
              <FormattedMessage
                id="publication.comments.message"
                defaultMessage="Будьте первым кто оставит комментарий"
              />
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

Comments.propTypes = {
  commentsData: PropTypes.array,
  resourceId: PropTypes.number,
  getResourceById: PropTypes.function,
}

export default Comments
