import { Dropdown } from 'react-bootstrap'
import { forwardRef } from 'react'
import classes from './Style.module.scss'
import PropTypes from 'prop-types'
import { ChevronForwardOutline } from 'react-ionicons'
import { useIntl } from 'react-intl'
import { CustomDropdownItem } from 'components'
import { useIntlContext } from '../../../../IntlProviderWrapper'

import { KgFlagIcon, RuFlagIcon, EnFlagIcon } from '../../../../icons'

const CustomToggle = forwardRef(({ onClick, name, flag }, ref) => (
  <button
    className={classes['custom-toggle']}
    ref={ref}
    onClick={e => {
      onClick?.(e)
      e.stopPropagation()
    }}
  >
    <span className="pr-2">{name}</span>
    <div>
      {flag}
      <ChevronForwardOutline
        width={'17px'}
        height={'17px'}
        style={{ marginLeft: '5px' }}
      />
    </div>
  </button>
))

CustomToggle.displayName = 'CustomToggle'

CustomToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  flag: PropTypes.node,
}

const DropdownLanguage = () => {
  const intl = useIntl()
  const { switchToEnglish, switchToRussian, switchToKyrgyz } = useIntlContext()

  return (
    <Dropdown drop="right">
      <Dropdown.Toggle
        as={CustomToggle}
        name={intl.formatMessage({
          id: 'profile.dropdown.language.interface',
          defaultMessage: 'Язык интерфейса',
        })}
        flag={
          intl.locale === 'ru' ? (
            <RuFlagIcon />
          ) : intl.locale === 'kg' ? (
            <KgFlagIcon />
          ) : (
            <EnFlagIcon />
          )
        }
      />
      <Dropdown.Menu className={classes.dropdownMenu}>
        <CustomDropdownItem onClick={switchToRussian}>
          Ru - Русский
        </CustomDropdownItem>
        <CustomDropdownItem onClick={switchToEnglish}>
          En - English
        </CustomDropdownItem>
        <CustomDropdownItem onClick={switchToKyrgyz}>
          Kg - Кыргызча
        </CustomDropdownItem>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DropdownLanguage
