import { applySnapshot, getSnapshot, types } from 'mobx-state-tree'
import {
  CategoriesListRequestModel,
  CategoriesPagedDto,
  CategoryModel,
  CategoryTreeModel,
  CategoriesTreePageDto,
  CategoriesForSelect,
} from 'stores/models'
import { apiV1 } from 'core/requests'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'

const CategoriesStore = types
  .model('CategoriesStore')
  .props({
    _categories: types.optional(CategoriesPagedDto, {}),
    _category: types.optional(CategoryModel, {}),
    _isLoading: types.optional(types.boolean, false),
    _isLoadingDetail: types.optional(types.boolean, false),
    _categoriesTree: types.optional(CategoriesTreePageDto, {}),
    _categoryTreeItem: types.optional(CategoryTreeModel, {}),
    _categoriesForSelect: types.array(CategoriesForSelect),
  })
  .actions(self => {
    const CATEGORY_API = '/api/tags'

    const setCategories = categoriesData => {
      applySnapshot(self._categories, categoriesData)
    }

    const setCategoriesTree = categoriesTreeData => {
      applySnapshot(self._categoriesTree, categoriesTreeData)
    }

    const setCategoryTreeItem = categoriesTreeData => {
      applySnapshot(self._categoryTreeItem, categoriesTreeData)
    }

    const setCategory = categoryData => {
      applySnapshot(self._category, categoryData)
    }

    const setIsLoading = isLoading => {
      self._isLoading = isLoading
    }

    const setIsLoadingDetail = isLoading => {
      self._isLoadingDetail = isLoading
    }

    const setCategoriesForSelect = data => {
      applySnapshot(self._categoriesForSelect, data)
    }

    const getCategories = async params => {
      const categoriesListRequest = fromPromise(
        apiV1.get(`${CATEGORY_API}`, {
          params: CategoriesListRequestModel.create(params),
        }),
      )

      when(() =>
        categoriesListRequest.case({
          fulfilled: response => {
            setCategories(response.data)
            return true
          },
        }),
      )
      return categoriesListRequest
    }

    const getCategoriesTree = async params => {
      const categoriesListRequest = fromPromise(
        apiV1.get(`${CATEGORY_API}/gettagstree`, {
          params: CategoriesListRequestModel.create(params),
        }),
      )

      when(() =>
        categoriesListRequest.case({
          fulfilled: response => {
            setCategoriesTree(response.data)
            return true
          },
        }),
      )
      return categoriesListRequest
    }

    const getCategoryTreeItem = async params => {
      const categoriesListRequest = fromPromise(
        apiV1.get(`${CATEGORY_API}/gettagstreebyid`, {
          params: CategoriesListRequestModel.create(params),
        }),
      )

      when(() =>
        categoriesListRequest.case({
          fulfilled: response => {
            setCategoryTreeItem(response.data)
            return true
          },
        }),
      )
      return categoriesListRequest
    }

    const getCategoriesForSelect = async params => {
      const categoriesListRequest = fromPromise(
        apiV1.get(`${CATEGORY_API}/gettagstree`, {
          params: CategoriesListRequestModel.create(params),
        }),
      )

      const recursive = el => {
        if (el.children.length === 0) {
          return {
            title: el.title,
            id: el.id,
          }
        }
        return [
          {
            title: el.title,
            id: el.id,
          },
          ...el.children.map(child => {
            return recursive(child)
          }),
        ]
      }

      when(() =>
        categoriesListRequest.case({
          fulfilled: response => {
            const categoriesForSelect = response.data.data
              .map(el => {
                if (el.children.length === 0) {
                  return {
                    title: el.title,
                    id: el.id,
                  }
                }
                return recursive(el)
              })
              .flat(Infinity)
            setCategoriesForSelect(categoriesForSelect)
            return true
          },
        }),
      )
      return categoriesListRequest
    }

    const getCategoryById = async id => {
      const categoryByIdRequest = fromPromise(
        apiV1.get(`${CATEGORY_API}/${id}`),
      )

      when(() =>
        categoryByIdRequest.case({
          fulfilled: response => {
            setCategory(response.data)
            return true
          },
        }),
      )
      return categoryByIdRequest
    }

    const getCategoryParents = async params => {
      return fromPromise(
        apiV1.get(`${CATEGORY_API}/gettagparents`, {
          params,
        }),
      )
    }

    const updatePriorityOrderOfTree = async categoriesTreeData => {
      apiV1.post(`${CATEGORY_API}/updatepriorityorder`, categoriesTreeData)
    }

    const createCategory = async categoriesData =>
      apiV1.post(CATEGORY_API, categoriesData)

    const editCategory = async categoriesData =>
      apiV1.put(CATEGORY_API, categoriesData, {
        params: { id: categoriesData.id },
      })

    const deleteCategory = async ({ id }) =>
      apiV1.delete(CATEGORY_API, { data: { id } })

    const clearCategories = () => {
      setCategoriesTree({})
      setCategoryTreeItem({})
    }

    return {
      getCategories,
      getCategoriesTree,
      createCategory,
      updatePriorityOrderOfTree,
      getCategoryTreeItem,
      getCategoryById,
      editCategory,
      deleteCategory,
      setIsLoading,
      setIsLoadingDetail,
      clearCategories,
      getCategoriesForSelect,
      getCategoryParents,
    }
  })
  .views(self => ({
    get categories() {
      return getSnapshot(self._categories)
    },
    get categoriesTree() {
      return getSnapshot(self._categoriesTree)
    },
    get categoryTreeItem() {
      return getSnapshot(self._categoryTreeItem)
    },
    get category() {
      return getSnapshot(self._category)
    },
    get categoriesList() {
      return this.categories.data
    },
    get isLoading() {
      return self._isLoading
    },
    get isLoadingDetail() {
      return self._isLoadingDetail
    },
    get categoriesForSelect() {
      return getSnapshot(self._categoriesForSelect)
    },
  }))

export default CategoriesStore
