import { types } from 'mobx-state-tree'
import { UserModel } from '.'
import PagedListMetaData from '../PagedListMetaData'

const UsersPageDto = types.model('UsersPageDto', {
  data: types.array(UserModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export default UsersPageDto
