import './Style.scss'
import PropTypes from 'prop-types'
import Header from './Header'
import { TitleWithLogo } from 'components'
import Navigation from './Navigation'
import UserInfo from './UserInfo'
import { SearchBar } from 'components'
import { Guard } from 'components'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import AsyncLocalStorage from '../../core/asyncLocalStorage'

const checkLocation = key => {
  let jsx = null

  switch (key) {
    case '/':
    case '/library':
    case '/news':
    case '/publications':
    case '/results/':
      jsx = (
        <Guard allowedRoles={['User', 'Moderator']}>
          <SearchBar />
        </Guard>
      )
      break
    default:
      break
  }

  return jsx
}

const checkForSearchBar = key => {
  if (
    key.includes('/publications/detail/') ||
    key.includes('/news/detail/') ||
    key.includes('/results')
  )
    return
  AsyncLocalStorage.setItem(
    'searchValue',
    JSON.stringify({
      title: '',
      category: 1,
    }),
  )
}

const Layout = ({ children, routes }) => {
  const location = useLocation()
  useEffect(() => {
    checkForSearchBar(location.pathname)
  }, [location])
  return (
    <section className="main_layout">
      <Header className="header">
        <div className="flex-grow-1 d-flex">
          <TitleWithLogo />
          <Navigation routes={routes} />
        </div>
        <div className="d-flex align-items-center">
          <UserInfo />
        </div>
      </Header>
      <main className="main_content">
        {checkLocation(location.pathname)}
        {children}
      </main>
    </section>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  routes: PropTypes.array,
}

export default Layout
