import { forwardRef, useRef } from 'react'
import './CustomDatePicker.scss'
import formatDate from 'core/formatDate'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { CalendarOutline } from 'react-ionicons'

const CustomInput = forwardRef(
  ({ value, onClick, customClass = '', errorMessage }, ref) => {
    return (
      <div
        className={`react-datepicker-custom-input ${customClass} ${
          errorMessage ? 'invalid' : ''
        }`}
        onClick={onClick}
        ref={ref}
      >
        <span>{value}</span>
        {value ? null : (
          <CalendarOutline
            color={'#4a5567'}
            height="20px"
            width="20px"
            className="react-datepicker-icon"
          />
        )}
      </div>
    )
  },
)

CustomInput.displayName = 'InputForDatePicker'

CustomInput.propTypes = {
  onClick: PropTypes.func,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  customClass: PropTypes.string,
}

const CustomDatePicker = ({ errorMessage, label, ...props }) => {
  const ref = useRef()
  const { startDate, endDate, onChange, selectsRange, placeholderText } = props
  return (
    <Form.Group className="mb-0">
      {!!label && (
        <Form.Label htmlFor={label} className="dp-label">
          {label}
        </Form.Label>
      )}
      {selectsRange ? (
        <DatePicker
          value={`${startDate ? formatDate(startDate.toISOString()) : ''}${
            endDate ? ` - ${formatDate(endDate.toISOString())}` : ''
          }`}
          placeholderText={placeholderText}
          selectsRange
          focusSelectedMonth
          startDate={startDate}
          endDate={endDate}
          selected={startDate}
          onChange={onChange}
          autoComplete="off"
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          isClearable
          shouldCloseOnSelect={false}
          customInput={
            <CustomInput
              value={`${startDate ? formatDate(startDate.toISOString()) : ''}${
                endDate ? ` - ${formatDate(endDate.toISOString())}` : ''
              }`}
              ref={ref}
            />
          }
        />
      ) : (
        <DatePicker
          {...props}
          autoComplete="off"
          customInput={
            <CustomInput
              value={props.value}
              ref={ref}
              errorMessage={errorMessage}
            />
          }
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          isClearable
        />
      )}

      {!!errorMessage && (
        <Form.Control.Feedback type="invalid" style={{ display: 'initial' }}>
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

CustomDatePicker.propTypes = {
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  selectsRange: PropTypes.string,
  placeholderText: PropTypes.string,
}

export default CustomDatePicker
