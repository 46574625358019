import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { SortIcon, StatsIcon } from '../../icons'
import { ArrowUpOutline, ArrowDownOutline } from 'react-ionicons'
import Overlay from '../Overlay'

const TableTitle = ({
  title,
  onClick,
  sortingKey,
  currentSorting,
  firstIcon,
}) => {
  const [sortBy, orderBy] = currentSorting?.split('.') || []
  return (
    <div
      onClick={() => onClick?.()}
      style={{
        cursor: sortingKey ? 'pointer' : 'initial',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {firstIcon && (
        <span className="p-0">
          <StatsIcon />
        </span>
      )}
      <Overlay text={<FormattedMessage {...title} />}>
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <FormattedMessage {...title} />
        </span>
      </Overlay>
      {!!sortingKey &&
        (sortBy === sortingKey ? (
          orderBy === 'asc' ? (
            <ArrowUpOutline width="15px" height="15px" className="p-0" />
          ) : (
            <ArrowDownOutline width="15px" height="15px" className="p-0" />
          )
        ) : (
          <SortIcon style={{ minWidth: '20px', height: '20px' }} />
        ))}
    </div>
  )
}

TableTitle.propTypes = {
  title: PropTypes.object,
  firstIcon: PropTypes.bool,
  onClick: PropTypes.func,
  sortingKey: PropTypes.string,
  currentSorting: PropTypes.string,
}

export default TableTitle
