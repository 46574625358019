import PropTypes from 'prop-types'
import { useStore } from 'hooks'

const Guard = ({ allowedRoles, fallback = null, children }) => {
  const { user } = useStore('authStore')

  return (
    <>{allowedRoles.some(role => role === user.role) ? children : fallback}</>
  )
}
Guard.propTypes = {
  allowedRoles: PropTypes.array,
  fallback: PropTypes.node,
  children: PropTypes.node,
}

export default Guard
