import { types } from 'mobx-state-tree'
import PagedListRequestModel from '../PagedListRequestModel'

const UserActivityRequestModel = PagedListRequestModel.named(
  'UserActivityRequestModel',
).props({
  sortBy: types.optional(types.string, ''),
  dateFrom: types.union(types.string, types.undefined, types.null),
  dateTo: types.union(types.string, types.undefined, types.null),
  actionType: types.union(types.string, types.undefined, types.null),
  entityType: types.union(types.string, types.undefined, types.null),
})

export default UserActivityRequestModel
