import { format } from 'date-fns'
import { ru, eu } from 'date-fns/locale'
import { getLocalStorage } from './utils'

export default function formatDate(date) {
  return new Date(date).toLocaleDateString('ru-Ru', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

export function formatWithLocale(date, formatStr = 'PP') {
  const locales = { ru, eu }
  const curLang = getLocalStorage('appStore')?._currentLanguage
  return format(date, formatStr, {
    locale: locales[curLang],
  })
}
