import AppStore from './AppStore'
import AuthStore from './AuthStore'
import NewsStore from './NewsStore'
import UsersStore from './UsersStore'
import ResourceTypesStore from './ResourceTypesStore'
import DictionaryStore from './DictionaryStore'
import ResourcesStore from './ResourcesStore'
import CategoriesStore from './CategoriesStore'

const storesRoot = {
  appStore: AppStore.create(),
  authStore: AuthStore.create(),
  newsStore: NewsStore.create(),
  usersStore: UsersStore.create(),
  resourceTypesStore: ResourceTypesStore.create(),
  dictionaryStore: DictionaryStore.create(),
  resourcesStore: ResourcesStore.create(),
  categoriesStore: CategoriesStore.create(),
}

export * from './AppStore'
export * from './AuthStore'
export * from './NewsStore'
export * from './UsersStore'
export * from './ResourceTypesStore'
export * from './DictionaryStore'
export * from './ResourcesStore'
export * from './CategoriesStore'

export {
  storesRoot as stores,
  AppStore,
  AuthStore,
  NewsStore,
  UsersStore,
  ResourceTypesStore,
  DictionaryStore,
  ResourcesStore,
  CategoriesStore,
}
