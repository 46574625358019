import { Dropdown } from 'react-bootstrap'
import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { ChevronDownOutline, AddOutline } from 'react-ionicons'
import { FormattedMessage } from 'react-intl'
import classes from './Style.module.scss'
import CustomDropdownItem from '../CustomDropdownItem'

const CustomToggle = forwardRef(({ onClick, className, name }, ref) => (
  <button
    className={`btn btn_iconblue btn-outline-primary ${className}`}
    type="button"
    ref={ref}
    onClick={e => {
      onClick?.(e)
      e.stopPropagation()
    }}
  >
    <AddOutline />
    <span className="px-1">{name}</span>
    <ChevronDownOutline width={'17px'} height={'17px'} />
  </button>
))

CustomToggle.displayName = 'CustomToggleButton'

CustomToggle.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.object,
}

const CustomDropdownButton = ({ options, selectField, className }) => {
  return (
    <Dropdown className={className}>
      <Dropdown.Toggle
        as={CustomToggle}
        name={<FormattedMessage id="publication.types.form.add.field.btn" />}
        className={classes.toggle}
      />
      <Dropdown.Menu className={classes.dropdownMenu}>
        {options.map(el => (
          <CustomDropdownItem key={el.id} onClick={() => selectField(el)}>
            {<FormattedMessage id={el.name} />}
          </CustomDropdownItem>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

CustomDropdownButton.propTypes = {
  options: PropTypes.array,
  selectField: PropTypes.func,
  className: PropTypes.string,
}

export default CustomDropdownButton
