import { OverlayTrigger, Popover } from 'react-bootstrap'
import PropTypes from 'prop-types'
import {
  TrashOutline,
  LockClosed,
  Key,
  LockOpen,
  Create,
  BookmarkOutline,
  Bookmark,
  DownloadOutline,
  ArrowDownOutline,
  ArrowUpOutline,
  AddOutline,
  TrashBin,
  CreateOutline,
} from 'react-ionicons'
import classes from './Style.module.scss'

const icons = props => {
  return {
    delete: <TrashOutline {...props} />,
    deleteBin: <TrashBin {...props} />,
    block: <LockClosed {...props} />,
    password: <Key {...props} />,
    unblock: <LockOpen {...props} />,
    edit: <Create {...props} />,
    editOut: <CreateOutline {...props} />,
    bookmarkOutline: <BookmarkOutline {...props} />,
    bookmark: <Bookmark {...props} />,
    download: <DownloadOutline {...props} />,
    down: <ArrowDownOutline {...props} />,
    up: <ArrowUpOutline {...props} />,
    add: <AddOutline {...props} />,
  }
}

const CrudButton = ({
  iconType,
  onClick,
  tooltip,
  disabled,
  width = '20px',
  height = '20px',
  color = '#4D55BC',
  as: As = 'button',
  backdrop,
  className,
  ...props
}) => {
  return (
    <OverlayTrigger
      overlay={
        <Popover
          style={{
            zIndex: 16000,
            border: 'none',
            boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1)',
            borderRadius: '2px',
          }}
          id={tooltip}
        >
          <Popover.Content>{tooltip}</Popover.Content>
        </Popover>
      }
    >
      <As
        type="button"
        disabled={disabled}
        onClick={event => {
          event.stopPropagation()
          onClick?.()
        }}
        className={`${classes.icon} ${className} ${
          backdrop ? classes.backdrop : ''
        }`}
        {...props}
      >
        {icons({ width, height, color })[iconType]}
      </As>
    </OverlayTrigger>
  )
}

CrudButton.propTypes = {
  iconType: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  backdrop: PropTypes.bool,
  as: PropTypes.elementType,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
}

export default CrudButton
