import { applySnapshot, getSnapshot, types } from 'mobx-state-tree'
import {
  ResourceTypePagedDto,
  ResourceTypeModel,
  ResourceTypeListRequestModel,
  ResourceTypeEditCommand,
  ResourceTypeCreateCommand,
} from 'stores/models'
import { apiV1 } from 'core/requests'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'

const ResourceTypesStore = types
  .model('ResourceTypesStore')
  .props({
    _resourceTypes: types.optional(ResourceTypePagedDto, {}),
    _resourceTypeDetail: types.optional(ResourceTypeModel, {}),
    _isLoading: types.optional(types.boolean, false),
    _isLoadingDetail: types.optional(types.boolean, false),
  })
  .actions(self => {
    const RESOURCE_TYPE_API = '/api/resourcetype'

    const setResourceTypes = typesData => {
      applySnapshot(self._resourceTypes, typesData)
    }

    const setResourceTypeDetail = typesData => {
      applySnapshot(self._resourceTypeDetail, typesData)
    }

    const setIsLoading = isLoading => {
      self._isLoading = isLoading
    }

    const setIsLoadingDetail = isLoading => {
      self._isLoadingDetail = isLoading
    }

    const getResourceTypesList = async params => {
      const publicationTypesRequest = fromPromise(
        apiV1.get(`${RESOURCE_TYPE_API}`, {
          params: ResourceTypeListRequestModel.create(params),
        }),
      )

      when(() =>
        publicationTypesRequest.case({
          fulfilled: response => {
            setResourceTypes(response.data)
            return true
          },
        }),
      )
      return publicationTypesRequest
    }

    const getResourceTypeDetail = async id => {
      const publicationTypeByIdRequest = fromPromise(
        apiV1.get(`${RESOURCE_TYPE_API}/${id}`),
      )

      when(() =>
        publicationTypeByIdRequest.case({
          fulfilled: response => {
            setResourceTypeDetail(response.data)
            return true
          },
        }),
      )
      return publicationTypeByIdRequest
    }

    const createResourceType = async typesData =>
      apiV1.post(RESOURCE_TYPE_API, ResourceTypeCreateCommand.create(typesData))

    const editResourceType = async typesData => {
      return apiV1.put(
        RESOURCE_TYPE_API,
        ResourceTypeEditCommand.create(typesData),
      )
    }

    const deleteResourceType = async id =>
      apiV1.delete(RESOURCE_TYPE_API, { data: { id } })

    return {
      getResourceTypesList,
      getResourceTypeDetail,
      createResourceType,
      editResourceType,
      deleteResourceType,
      setIsLoading,
      setIsLoadingDetail,
    }
  })
  .views(self => ({
    get types() {
      return getSnapshot(self._resourceTypes)
    },
    get typeDetail() {
      return getSnapshot(self._resourceTypeDetail)
    },
    get typesList() {
      return this.types.data
    },
    get typesMetadata() {
      return this.types.metadata
    },
    get isLoading() {
      return self._isLoading
    },
    get isLoadingDetail() {
      return self._isLoadingDetail
    },
  }))

export default ResourceTypesStore
