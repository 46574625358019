import { types } from 'mobx-state-tree'
import ResourceCoAuthorModel from './ResourceCoAuthorModel'
import ResourceFileModel from './ResourceFileModel'
import UserResourceTagModel from './UserResourceTagModel'
import ResourceValueModel from './ResourceValueModel'
import ResourceCommentModel from './ResourceCommentModel'
import CommonTagModel from '../common/TagModel'
import CommentModel from '../common/CommentModel'

const ResourceModel = types.model('ResourceModel', {
  id: types.optional(types.number, 0),
  name: types.maybeNull(types.string),
  coAuthors: types.optional(types.array(ResourceCoAuthorModel), []),
  description: types.maybeNull(types.string),
  resourceType: types.maybeNull(types.string),
  createdDate: types.maybeNull(types.string),
  resourceComments: types.optional(types.array(ResourceCommentModel), []),
  resourceTypeId: types.optional(types.number, 0),
  resourceFiles: types.optional(types.array(ResourceFileModel), []),
  resourceTags: types.optional(types.array(CommonTagModel), []),
  isConfirmed: types.optional(types.boolean, false),
  isFavorite: types.optional(types.boolean, false),
  userTags: types.optional(types.array(UserResourceTagModel), []),
  viewCount: types.optional(types.number, 0),
  createdByUserId: types.maybeNull(types.string),
  oldFilesIds: types.optional(types.array(types.integer), []),
  mainFile: types.maybeNull(types.optional(ResourceFileModel, {})),
  resourcesValues: types.optional(types.array(ResourceValueModel), []),
  favoritesUsersIds: types.optional(types.array(types.string), []),
  resourceStatus: types.optional(types.number, 1),
  userCommentToResources: types.optional(types.array(CommentModel), []),
  mainFilePath: types.maybeNull(types.string),
  downloadCount: types.optional(types.number, 0),
})

export default ResourceModel
