import { types } from 'mobx-state-tree'
import UserActivityModel from './UserActivityModel'
import PagedListMetaData from '../PagedListMetaData'

const UserActivityPagedDtp = types.model('UserActivityPagedDtp', {
  data: types.array(UserActivityModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export default UserActivityPagedDtp
