import { applySnapshot, getSnapshot, types } from 'mobx-state-tree'
import { NewsListRequestModel, NewsPagedDto, NewsModel } from 'stores/models'
import { apiV1 } from 'core/requests'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'

const NewsStore = types
  .model('NewsStore')
  .props({
    _news: types.optional(NewsPagedDto, {}),
    _oneNews: types.optional(NewsModel, {}),
    _isLoading: types.optional(types.boolean, false),
    _isLoadingDetail: types.optional(types.boolean, false),
  })
  .actions(self => {
    const NEWS_API = '/api/news'

    const setNews = newsData => {
      applySnapshot(self._news, newsData)
    }

    const setOneNews = newsData => {
      applySnapshot(self._oneNews, newsData)
    }

    const setIsLoading = isLoading => {
      self._isLoading = isLoading
    }

    const setIsLoadingDetail = isLoading => {
      self._isLoadingDetail = isLoading
    }

    const getNews = async params => {
      const newsListRequest = fromPromise(
        apiV1.get(`${NEWS_API}`, {
          params: NewsListRequestModel.create(params),
        }),
      )

      when(() =>
        newsListRequest.case({
          fulfilled: response => {
            setNews(response.data)
            return true
          },
        }),
      )
      return newsListRequest
    }

    const getNewsById = async id => {
      const newsByIdRequest = fromPromise(apiV1.get(`${NEWS_API}/${id}`))

      when(() =>
        newsByIdRequest.case({
          fulfilled: response => {
            setOneNews(response.data)
            return true
          },
        }),
      )
      return newsByIdRequest
    }

    const createNews = async newsData =>
      apiV1.post(NEWS_API, newsData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

    const editNews = async newsData =>
      apiV1.put(NEWS_API, newsData, {
        params: { id: newsData.id },
        headers: { 'Content-Type': 'multipart/form-data' },
      })

    const deleteNews = async ({ id }) =>
      apiV1.delete(NEWS_API, { data: { id } })

    return {
      getNews,
      createNews,
      getNewsById,
      editNews,
      deleteNews,
      setIsLoading,
      setIsLoadingDetail,
    }
  })
  .views(self => ({
    get news() {
      return getSnapshot(self._news)
    },
    get oneNews() {
      return getSnapshot(self._oneNews)
    },
    get newsList() {
      return this.news.data
    },
    get newsMetadata() {
      return this.news.metadata
    },
    get isLoading() {
      return self._isLoading
    },
    get isLoadingDetail() {
      return self._isLoadingDetail
    },
  }))

export default NewsStore
