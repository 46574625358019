import { useEffect, useState } from 'react'
import usePersistedState from './usePersistedState'

const useSortingState = (initialSortBy, initialOrderBy = 'desc') => {
  const [sorting, setSorting] = useState(
    () => `${initialSortBy}.${initialOrderBy}`,
  )

  const toggleSorting = sortBy => {
    setSorting(prevSortig => {
      const [prevSortBy, orderBy] = prevSortig?.split('.')

      return sortBy === prevSortBy
        ? `${sortBy}.${orderBy === 'asc' ? 'desc' : 'asc'}`
        : `${sortBy}.${initialOrderBy}`
    })
  }

  useEffect(() => {}, [sorting])

  return [sorting, toggleSorting]
}

const usePersistedSorting = (
  persistName,
  storage,
  initialSortBy,
  initialOrderBy = 'desc',
) => {
  const [sorting, setSorting] = usePersistedState(
    persistName,
    `${initialSortBy}.${initialOrderBy}`,
    storage,
  )
  const toggleSorting = sortBy => {
    setSorting(prevSortig => {
      const [prevSortBy, orderBy] = prevSortig?.split('.')

      return sortBy === prevSortBy
        ? `${sortBy}.${orderBy === 'asc' ? 'desc' : 'asc'}`
        : `${sortBy}.${initialOrderBy}`
    })
  }

  return [sorting, toggleSorting]
}

export { useSortingState, usePersistedSorting }
