import { types } from 'mobx-state-tree'
import NewsFileModel from './NewsFileModel'

const NewsModel = types.model('NewsModel', {
  id: types.optional(types.integer, 0),
  title: types.maybeNull(types.string),
  body: types.maybeNull(types.string),
  source: types.maybeNull(types.string),
  createdDate: types.maybeNull(types.string),
  favoritesUsersIds: types.optional(types.array(types.string), []),
  newsMainPicture: types.optional(NewsFileModel, {}, [null, undefined]),
  newsFiles: types.optional(types.array(NewsFileModel), [], [null, undefined]),
  oldFilesIds: types.optional(types.array(types.number), []),
})

export default NewsModel
