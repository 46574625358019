import axios from 'axios'
import qs from 'qs'
import { getFormDataPublications } from 'core/utils'
import { filesDataContainer } from '../stores/domain/FilesDataStore'

const apiV1 = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const setupInterceptors = (appStore, authStore, resourcesStore) => {
  apiV1.interceptors.request.use(
    config => {
      if (config.url === '/api/account/refreshtoken') {
        authStore.setIsPending(true)
      }
      return {
        ...config,
        headers: {
          ...config.headers,
          'Accept-Language':
            appStore.currentLanguage === 'ru' ? 'ru-RU' : 'en-US',
          Authorization: `Bearer ${authStore.user.accessToken}`,
        },
        paramsSerializer: params =>
          qs.stringify(params, { allowDots: true, skipNulls: true }),
      }
    },
    error => Promise.reject(error),
  )

  apiV1.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config

      if (
        error.response?.status === 403 &&
        originalRequest.url != '/api/account/refreshtoken'
      ) {
        authStore.setIsBlocked(true)
      }

      if (
        error.response?.status === 401 &&
        originalRequest.url === '/api/account/refreshtoken' &&
        !authStore.isPending
      ) {
        authStore.signOut()
        Promise.reject(error)
      }

      if (
        error.response?.status === 401 &&
        !originalRequest._retry &&
        !authStore.isPending
      ) {
        try {
          originalRequest._retry = true
          const refreshToken = authStore.user.refreshToken

          const response = await authStore.refreshToken({
            refreshToken,
          })

          apiV1.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${response.data.accessToken}`

          if (
            originalRequest.url.includes('/api/resource') &&
            (originalRequest.method === 'post' ||
              originalRequest.method === 'put')
          ) {
            const response = await apiV1(originalRequest)
            await resourcesStore.attachResourceFile(
              getFormDataPublications({
                ...filesDataContainer,
                resourceId: response.data,
              }),
            )
            authStore.setIsPending(false)
          } else {
            await apiV1(originalRequest)
            authStore.setIsPending(false)
          }
        } catch (e) {
          authStore.setIsPending(false)
          authStore.signOut()
        }
      }

      return Promise.reject(error)
    },
  )
}

export { setupInterceptors, apiV1 }
