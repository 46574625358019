import classes from './Style.module.scss'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { ChatbubbleEllipses } from 'react-ionicons'
import { formatWithLocale } from 'core/formatDate'

const CommentsCell = ({ comments, setCommentsModal }) => {
  return (
    <div
      className={classes.container}
      onClick={
        setCommentsModal
          ? () => setCommentsModal({ open: true, comments: comments })
          : null
      }
    >
      <span className={classes.icon}>
        <ChatbubbleEllipses
          width="15px"
          height="15px"
          color="#4D55BC"
          className="mr-1"
        />
        <FormattedMessage id="publication.request.table.comment" />
      </span>
      <ul className={[classes.comment, classes.scroll].join(' ')}>
        {comments.map(comment => (
          <li key={comment.commentDateTime}>
            <div>
              {`${formatWithLocale(
                new Date(comment.commentDateTime),
                'dd MMMM, yyyy HH:mm:ss',
              )} :`}
            </div>
            <div>{comment.comment}</div>
          </li>
        ))}
      </ul>
    </div>
  )
}

CommentsCell.propTypes = {
  comments: PropTypes.array.isRequired,
  setCommentsModal: PropTypes.func.isRequired,
}

export default CommentsCell
