import { useEffect, useState } from 'react'
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js'
import { Form } from 'react-bootstrap'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './Style.scss'

const WYSIWYGEditor = ({ onChange, title, value, errorMessage }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [isValueExists, setIsValueExists] = useState(true)

  const onEditorStateChange = editorState => {
    setEditorState(editorState)
    setIsValueExists(false)
    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  useEffect(() => {
    const contentBlock = convertFromHTML(value)
    if (contentBlock && isValueExists) {
      const contentState = ContentState.createFromBlockArray(contentBlock)
      const editState = EditorState.createWithContent(contentState)
      !editorState.getCurrentContent().hasText() && setEditorState(editState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div className="my-2">
      {title ? (
        <p className="wysiwyg-title">
          <FormattedMessage id={title} />
        </p>
      ) : null}
      <Editor
        toolbar={{
          options: [
            'inline',
            'fontFamily',
            'fontSize',
            'textAlign',
            'list',
            'history',
            'remove',
          ],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
          textAlign: { inDropdown: true },
          fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16],
          },
        }}
        wrapperClassName={errorMessage ? 'invalid' : ''}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
      {!!errorMessage && (
        <Form.Control.Feedback type="invalid" style={{ display: 'initial' }}>
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </div>
  )
}

WYSIWYGEditor.propTypes = {
  title: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default WYSIWYGEditor
