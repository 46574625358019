import { types } from 'mobx-state-tree'
import PagedListRequestModel from '../PagedListRequestModel'

const ResourceListRequestModel = PagedListRequestModel.named(
  'ResourceListRequestModel',
).props({
  createdByUserId: types.optional(types.string, ''),
  isConfirmed: types.maybeNull(types.boolean),
  sortBy: types.optional(types.string, ''),
  tagId: types.maybeNull(types.number),
  filter: types.maybeNull(types.number),
})

export default ResourceListRequestModel
