import { pageSizes } from 'core/constants'
import Select from 'react-select'
import styles from './PageSizeSelectStyles'
import './style.scss'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

const PageSizeSelect = ({ pageSize, onPageSizeChange, className = '' }) => {
  return (
    <div className={`pagination-sort ${className}`}>
      <p>
        <FormattedMessage
          id="page_size_select.items_per_page"
          defaultMessage="Элементов на странице:"
        />
      </p>
      <Select
        className="pageSize-select"
        value={{
          label: pageSize,
          value: pageSize,
        }}
        options={pageSizes.map(pageSize => ({
          label: pageSize,
          value: pageSize,
        }))}
        onChange={pageSize => {
          onPageSizeChange(pageSize.value)
        }}
        styles={styles}
        isClearable={false}
        menuPlacement="auto"
      />
    </div>
  )
}

PageSizeSelect.propTypes = {
  pageSize: PropTypes.number,
  onPageSizeChange: PropTypes.func,
  className: PropTypes.string,
}

export default PageSizeSelect
