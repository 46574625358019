import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const Pagination = ({
  currentPage,
  pageCount,
  totalElement,
  firstItem,
  lastItem,
  onPageChange,
}) => {
  let item = firstItem === lastItem ? firstItem : `${firstItem} - ${lastItem}`
  return pageCount ? (
    <div className="d-flex align-items-center">
      <p className="mt-1 small_content gray mr-4">
        <FormattedMessage
          id="pagination.count"
          defaultMessage={`${item} из ${totalElement} элементов`}
          values={{
            item,
            totalElement,
          }}
        />
      </p>
      <ReactPaginate
        containerClassName="pagination"
        activeClassName="active"
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        forcePage={currentPage - 1}
        onPageChange={({ selected }) => {
          onPageChange(selected + 1)
        }}
      />
    </div>
  ) : null
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  firstItem: PropTypes.number,
  lastItem: PropTypes.number,
  totalElement: PropTypes.number,
  onPageChange: PropTypes.func,
}

export default Pagination
