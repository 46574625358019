import { lazy } from 'react'
import FAQPage from './views/FAQPage'

//MainPage
const MainPage = lazy(() => import('views/MainPage'))

//Library
const LibraryPage = lazy(() => import('views/Library'))
const LibraryItem = lazy(() => import('views/Library/LibraryItem'))

//News
const NewsPage = lazy(() => import('views/News'))
const DetailNewsPage = lazy(() => import('views/News/DetailNews'))
const NewsForm = lazy(() => import('views/News/NewsForm'))

//Admin
const AdminPage = lazy(() => import('views/Admin'))
const ActivityPage = lazy(() => import('views/Admin/Activity'))
const PublicationTypes = lazy(() => import('views/Admin/PublicationTypes'))
const PublicationTypesForm = lazy(() =>
  import('views/Admin/PublicationTypes/PublicationTypesForm'),
)
const CategoriesPage = lazy(() => import('views/Admin/Categories'))
const AdminPublicationRequest = lazy(() =>
  import('views/Admin/PublicationRequest'),
)

//Users
const UsersPage = lazy(() => import('views/Admin/Users'))
const UsersForm = lazy(() => import('views/Admin/Users/UsersForm'))

//Profile
const ProfilePage = lazy(() => import('views/ProfilePage'))
const FavoritesPage = lazy(() => import('views/ProfilePage/FavoritePage'))

//Publications
const PublicationPage = lazy(() => import('views/Publications'))
const DetailPublicationPage = lazy(() =>
  import('views/Publications/DetailPublication'),
)
const PublicationForm = lazy(() => import('views/Publications/PublicationForm'))

//PublicationRequest
const PublicationRequest = lazy(() => import('views/PublicationRequest'))

const ResultPage = lazy(() => import('views/Results'))

export const MAIN_ROUTE = '/'

export const LIBRARY_ROUTE = '/library'
export const LIBRARY_FILTERED_ROUTE = `${LIBRARY_ROUTE}/:id`

export const NEWS_ROUTE = '/news'
export const ADD_NEWS_ROUTE = `${NEWS_ROUTE}/create`
export const EDIT_NEWS_ROUTE = `${NEWS_ROUTE}/edit/:id`
export const DETAIL_NEWS_ROUTE = `${NEWS_ROUTE}/detail/:id`

export const MY_PROFILE_ROUTE = '/account'
export const MY_PROFILE_NEWS_ROUTE = `${MY_PROFILE_ROUTE}/news`
export const MY_PROFILE_PUBLICATION_ROUTE = `${MY_PROFILE_ROUTE}/my-publications`
export const MY_PUBLICATION_DETAIL_ROUTE = `${MY_PROFILE_ROUTE}/my-publication/detail/:id`
export const MY_PROFILE_FAVORITES_ROUTE = `${MY_PROFILE_ROUTE}/favorites`
export const MY_PROFILE_PUBLICATION_REQUEST_ROUTE = `${MY_PROFILE_ROUTE}/publications/request`

export const PUBLICATIONS_ROUTE = '/publications'
export const ADD_PUBLICATION_ROUTE = `${PUBLICATIONS_ROUTE}/create`
export const EDIT_PUBLICATION_ROUTE = `${PUBLICATIONS_ROUTE}/edit/:id`
export const DETAIL_PUBLICATION_ROUTE = `${PUBLICATIONS_ROUTE}/detail/:id`
export const PUBLICATION_REQUEST_ROUTE = `${PUBLICATIONS_ROUTE}/request`
export const PUBLICATION_REQUEST_DETAIL_ROUTE = `${PUBLICATIONS_ROUTE}/request/detail/:id`

export const ADMIN_ROUTE = '/admin'
export const ADMIN_USERS_ROUTE = `${ADMIN_ROUTE}/users`
export const ADMIN_ACTIVITY_ROUTE = `${ADMIN_ROUTE}/activity`
export const ADMIN_PUBLICATION_TYPES_ROUTE = `${ADMIN_ROUTE}/publication/types`
export const ADMIN_CATEGORIES_ROUTE = `${ADMIN_ROUTE}/categories`
export const ADMIN_PUBLICATION_REQUEST_ROUTE = `${ADMIN_ROUTE}/publications/request`

export const ADD_PUBLICATION_TYPES_ROUTE = '/publication/types/create'
export const EDIT_PUBLICATION_TYPES_ROUTE = '/publication/types/edit/:id'

export const ADD_CATEGORIES_ROUTE = '/categories/create'
export const EDIT_CATEGORIES_ROUTE = '/categories/edit/:id'

export const ADD_USERS_ROUTE = '/users/create'
export const EDIT_USERS_ROUTE = '/users/edit/:id'

export const RESULTS_ROUTE = '/results'

export const FAQ_ROUTE = '/faq'

const routes = [
  {
    path: MAIN_ROUTE,
    component: MainPage,
    name: {
      id: 'nav.main',
      defaultMessage: 'KMS',
    },
    access: ['Moderator', 'User'],
  },
  {
    navigated: true,
    path: LIBRARY_ROUTE,
    component: LibraryPage,
    name: {
      id: 'nav.library',
      defaultMessage: 'Библиотека',
    },
    access: ['Moderator', 'User'],
  },
  {
    path: LIBRARY_FILTERED_ROUTE,
    component: LibraryItem,
    name: {
      id: 'nav.library',
      defaultMessage: 'Библиотека',
    },
    access: ['Moderator', 'User'],
  },
  {
    navigated: true,
    path: NEWS_ROUTE,
    component: NewsPage,
    name: {
      id: 'nav.news',
      defaultMessage: 'Новости',
    },
    access: ['Moderator', 'Administrator', 'User'],
  },
  {
    path: DETAIL_NEWS_ROUTE,
    component: DetailNewsPage,
    name: {
      id: 'nav.news',
      defaultMessage: 'Новости',
    },
    access: ['Moderator', 'Administrator', 'User'],
  },
  {
    path: RESULTS_ROUTE,
    component: ResultPage,
    name: {
      id: 'qwerty',
      defaultMessage: 'Results',
    },
    access: ['Moderator', 'User'],
  },
  {
    path: ADD_NEWS_ROUTE,
    component: NewsForm,
    name: {
      id: 'nav.news',
      defaultMessage: 'Новости',
    },
    access: ['Moderator', 'Administrator', 'User'],
  },
  {
    path: EDIT_NEWS_ROUTE,
    component: NewsForm,
    name: {
      id: 'nav.news',
      defaultMessage: 'Новости',
    },
    access: ['Moderator', 'Administrator', 'User'],
  },
  {
    navigated: true,
    path: PUBLICATIONS_ROUTE,
    component: PublicationPage,
    name: {
      id: 'nav.publication',
      defaultMessage: 'Публикации',
    },
    access: ['Moderator', 'Administrator', 'User'],
  },
  {
    navigated: true,
    path: FAQ_ROUTE,
    component: FAQPage,
    name: {
      id: 'faq',
      defaultMessage: 'FAQ',
    },
    access: ['Moderator', 'Administrator', 'User'],
  },
  {
    path: ADD_PUBLICATION_ROUTE,
    component: PublicationForm,
    name: {
      id: 'nav.my.publication',
      defaultMessage: 'Мои публикации',
    },
    access: ['Moderator', 'User'],
  },
  {
    path: EDIT_PUBLICATION_ROUTE,
    component: PublicationForm,
    name: {
      id: 'nav.my.publication',
      defaultMessage: 'Мои публикации',
    },
    access: ['Moderator', 'Administrator', 'User'],
  },
  {
    path: DETAIL_PUBLICATION_ROUTE,
    component: DetailPublicationPage,
    name: {
      id: 'nav.my.publication',
      defaultMessage: 'Мои публикации',
    },
    access: ['Administrator', 'Moderator', 'User'],
  },
  {
    path: PUBLICATION_REQUEST_DETAIL_ROUTE,
    component: DetailPublicationPage,
    name: {
      id: 'nav.my.publication',
      defaultMessage: 'Мои публикации',
    },
    access: ['Administrator', 'Moderator', 'User'],
  },
  {
    path: MY_PUBLICATION_DETAIL_ROUTE,
    component: DetailPublicationPage,
    name: {
      id: 'nav.my.publication',
      defaultMessage: 'Мои публикации',
    },
    access: ['Administrator', 'Moderator', 'User'],
  },
  {
    path: PUBLICATION_REQUEST_ROUTE,
    component: AdminPublicationRequest,
    name: {
      id: 'profile.dropdown.publication.request',
      defaultMessage: 'Заявки на публикацию',
    },
    access: ['Administrator', 'Moderator', 'User'],
  },
  {
    path: MY_PROFILE_ROUTE,
    component: ProfilePage,
    name: {
      id: 'profile.dropdown.my.profile',
      defaultMessage: 'Личный кабинет',
    },
    access: ['Moderator', 'User'],
    children: [
      {
        navigated: true,
        path: MY_PROFILE_PUBLICATION_ROUTE,
        component: PublicationPage,
        name: {
          id: 'nav.my.publication',
          defaultMessage: 'Мои публикации',
        },
        access: ['Moderator', 'User'],
      },
      {
        navigated: true,
        path: MY_PROFILE_NEWS_ROUTE,
        component: NewsPage,
        name: {
          id: 'nav.news',
          defaultMessage: 'Новости',
        },
        access: ['Moderator'],
      },
      {
        navigated: true,
        path: MY_PROFILE_FAVORITES_ROUTE,
        component: FavoritesPage,
        name: {
          id: 'nav.favorite',
          defaultMessage: 'Избранное',
        },
        access: ['Moderator', 'User'],
      },
      {
        navigated: true,
        path: MY_PROFILE_PUBLICATION_REQUEST_ROUTE,
        component: PublicationRequest,
        name: {
          id: 'publication.request.title',
          defaultMessage: 'Мои заявки',
        },
        access: ['Moderator', 'User'],
      },
    ],
  },
  {
    navigated: true,
    path: ADMIN_ROUTE,
    component: AdminPage,
    name: {
      id: 'nav.admin',
      defaultMessage: 'Админ-панель',
    },
    access: ['Administrator', 'Moderator'],
    children: [
      {
        navigated: true,
        path: ADMIN_USERS_ROUTE,
        component: UsersPage,
        name: {
          id: 'nav.users',
          defaultMessage: 'Пользователи',
        },
        access: ['Administrator'],
      },
      {
        navigated: true,
        path: ADMIN_ACTIVITY_ROUTE,
        component: ActivityPage,
        name: {
          id: 'nav.activity',
          defaultMessage: 'Активность',
        },
        access: ['Administrator'],
      },
      {
        navigated: true,
        path: ADMIN_PUBLICATION_TYPES_ROUTE,
        component: PublicationTypes,
        name: {
          id: 'publication.types.page.title',
          defaultMessage: 'Типы публикаций',
        },
        access: ['Administrator'],
      },
      {
        navigated: true,
        path: ADMIN_CATEGORIES_ROUTE,
        component: CategoriesPage,
        name: {
          id: 'nav.categories',
          defaultMessage: 'Категории',
        },
        access: ['Administrator', 'Moderator'],
      },
      {
        navigated: true,
        path: ADMIN_PUBLICATION_REQUEST_ROUTE,
        component: AdminPublicationRequest,
        name: {
          id: 'profile.dropdown.publication.request',
          defaultMessage: 'Заявки на публикацию',
        },
        access: ['Moderator'],
      },
    ],
  },
  {
    path: ADD_USERS_ROUTE,
    component: UsersForm,
    name: {
      id: 'nav.users',
      defaultMessage: 'Пользователи',
    },
    access: ['Administrator'],
  },
  {
    path: EDIT_USERS_ROUTE,
    component: UsersForm,
    name: {
      id: 'nav.users',
      defaultMessage: 'Пользователи',
    },
    access: ['Administrator'],
  },
  {
    path: ADD_PUBLICATION_TYPES_ROUTE,
    component: PublicationTypesForm,
    name: {
      id: 'profile.dropdown.publicate.types',
      defaultMessage: 'Типы публикаций',
    },
    access: ['Administrator'],
  },
  {
    path: EDIT_PUBLICATION_TYPES_ROUTE,
    component: PublicationTypesForm,
    name: {
      id: 'profile.dropdown.publicate.types',
      defaultMessage: 'Типы публикаций',
    },
    access: ['Administrator'],
  },
]

export default routes
