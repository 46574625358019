/* eslint-disable react/display-name */
import PropTypes from 'prop-types'
import classes from './Style.module.scss'
import { useCallback, useEffect, useState } from 'react'
import React from 'react'
import { FormControl } from 'react-bootstrap'
import { useStore } from 'hooks'
import { handleError } from 'core/utils'
import { useIntl } from 'react-intl'
import MenuItem from './menu_item'

const CustomMenu = React.forwardRef(
  (
    { children, style, className, selectValue, unSelectValue, choosen },
    ref,
  ) => {
    const dictionaryStore = useStore('dictionaryStore')
    const intl = useIntl()
    const [value, setValue] = useState('')
    const [tags, setTags] = useState([])

    const getCategoriesForSelect = useCallback(async () => {
      try {
        dictionaryStore.setIsLoading(true)
        const { data } = await dictionaryStore.getTags({
          pageNumber: 1,
          pageSize: 100000,
          sortBy: 'LinkedResourceCount.desc',
        })
        setTags(data.map(el => ({ id: el.id, title: el.name })))
      } catch (error) {
        handleError(
          error,
          intl.formatMessage({
            id: 'global.default_error_message',
            defaultMessage: 'Произошла ошибка',
          }),
        )
      } finally {
        dictionaryStore.setIsLoading(false)
      }
    }, [dictionaryStore, intl])

    useEffect(() => {
      getCategoriesForSelect()
    }, [getCategoriesForSelect])

    return (
      <div ref={ref} style={style} className={className}>
        <FormControl
          className={classes.filter}
          placeholder={intl.formatMessage({
            id: 'global.search',
            defaultMessage: 'Поиск',
          })}
          onChange={e => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {value
            ? tags
                .filter(
                  el =>
                    !value ||
                    el.title.toLowerCase().startsWith(value.toLowerCase()),
                )
                .map(el => {
                  return (
                    <MenuItem
                      key={el.id}
                      el={el}
                      selectValue={selectValue}
                      unSelectValue={unSelectValue}
                      choosen={choosen}
                    />
                  )
                })
            : React.Children.toArray(children)}
        </ul>
      </div>
    )
  },
)

CustomMenu.propTypes = {
  children: PropTypes.arrayOf(Node),
  style: PropTypes.object,
  className: PropTypes.string,
  'aria-labelledby': PropTypes.string,
  selectValue: PropTypes.func,
  unSelectValue: PropTypes.func,
  choosen: PropTypes.array,
}

export default CustomMenu
