import PropTypes from 'prop-types'
import classes from './Style.module.scss'

const CustomButton = ({ alt, src, className }) => {
  const handleContextMenu = event => {
    event.preventDefault()
    return false
  }
  return (
    <div
      className={classes.image_container}
      onContextMenu={e => handleContextMenu(e)}
    >
      <img alt={alt} src={src} className={className} />
    </div>
  )
}

CustomButton.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
}

export default CustomButton
