import { types } from 'mobx-state-tree'
import PagedListRequestModel from '../PagedListRequestModel'

const FavoriteResourceListRequestModel = PagedListRequestModel.named(
  'FavoriteResourceListRequestModel',
).props({
  userId: types.optional(types.string, ''),
})

export default FavoriteResourceListRequestModel
