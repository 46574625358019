import { types } from 'mobx-state-tree'
import ResourceTypeTemplateModel from './ResourceTypeTemplateModel'

const ResourceTypesCreateCommand = types.model('ResourceTypesCreateCommand', {
  name: types.string,
  resourcesTypeTemplates: types.optional(
    types.array(ResourceTypeTemplateModel),
    [],
  ),
})

export default ResourceTypesCreateCommand
