import { useLayoutEffect, useRef, useState } from 'react'
import classes from './Style.module.scss'
import PropTypes from 'prop-types'
import { CloseOutline } from 'react-ionicons'

const TagsItem = props => {
  const [focus, setFocus] = useState(false)
  const onclick = () => {
    props.onRemove(props.index, focus)
  }
  const ref = useRef()
  useLayoutEffect(() => {
    if (ref.current && props.focus) {
      ref.current.focus()
    }
  }, [props.focus])

  return (
    <div
      data-testid="tag-element"
      ref={ref}
      className={`${classes.tagsItem} ${props.className}`}
      onFocus={() => {
        setFocus(true)
      }}
      onBlur={() => {
        setFocus(false)
      }}
    >
      <div className={classes.text}>{props.value}</div>
      <div className={classes.closeBtn} onClick={onclick}>
        <CloseOutline
          style={{ fill: 'var(--bs-white)', opacity: 1 }}
          width="18px"
          height="18px"
        />
      </div>
    </div>
  )
}

TagsItem.propTypes = {
  onRemove: PropTypes.func,
  focus: PropTypes.bool,
  index: PropTypes.number,
  onSelectedIndex: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
}

export default TagsItem
