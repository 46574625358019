import { useState } from 'react'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { EyeOutline, EyeOffOutline } from 'react-ionicons'
import classes from './Input.module.scss'

const PasswordInput = ({ errorMessage, register, ...props }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <div className="position-relative">
      <Form.Control
        ref={register}
        type={isVisible ? 'text' : 'password'}
        {...props}
      />
      {isVisible ? (
        <EyeOutline
          className={classes.passwordIcon}
          color="#5b6bd5"
          width="20px"
          height="20px"
          onClick={event => {
            event.preventDefault()
            setIsVisible(!isVisible)
          }}
        />
      ) : (
        <EyeOffOutline
          className={classes.passwordIcon}
          color="#718096"
          width="20px"
          height="20px"
          onClick={event => {
            event.preventDefault()
            setIsVisible(!isVisible)
          }}
        />
      )}
      {errorMessage && (
        <Form.Text className="invalid-feedback">{errorMessage}</Form.Text>
      )}
    </div>
  )
}

PasswordInput.propTypes = {
  register: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  borderNone: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default PasswordInput
