import { types } from 'mobx-state-tree'

const ResourceValueModel = types.model('ResourceValueModel', {
  id: types.maybeNull(types.optional(types.number, 0)),
  resourceTypeTemplateId: types.optional(types.number, 0),
  resourceId: types.optional(types.number, 0),
  typeTemplateId: types.optional(types.number, 0),
  value: types.optional(types.string, '0'),
})

export default ResourceValueModel
