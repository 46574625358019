import { types } from 'mobx-state-tree'

const CommentModel = types.model('CommentModel', {
  comment: types.maybeNull(types.string),
  createdDate: types.maybeNull(types.string),
  resourceId: types.maybeNull(types.number),
  userId: types.maybeNull(types.string),
  userName: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
})

export default CommentModel
