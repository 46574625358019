import { types } from 'mobx-state-tree'
import PagedListRequestModel from '../PagedListRequestModel'

const ResourceTypesListRequestModel = PagedListRequestModel.named(
  'ResourceTypesListRequestModel',
).props({
  search: types.optional(types.string, ''),
  sortBy: types.optional(types.string, ''),
})

export default ResourceTypesListRequestModel
