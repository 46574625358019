import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'
import CreatableSelect from 'react-select/creatable'
import { styles } from './CustomSelectStyles'
import PropTypes from 'prop-types'

const CustomSelect = ({
  type,
  label,
  getSelectRef,
  disabled,
  errorMessage,
  getOptionValue,
  value,
  isMulti,
  options,
  ...attributes
}) => (
  <div className="d-flex flex-column flex-grow-1 customSelect">
    {label && (
      <span
        style={{
          padding: '0.3333rem 0',
          marginBottom: 0,
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '20px',
          color: '#718096',
        }}
      >
        {label}
      </span>
    )}
    {type === 'async-select' ? (
      <AsyncSelect ref={getSelectRef} styles={styles} {...attributes} />
    ) : type === 'async-creatable-select' ? (
      <AsyncCreatableSelect
        ref={getSelectRef}
        styles={styles}
        {...attributes}
      />
    ) : type === 'creatable-select' ? (
      <CreatableSelect ref={getSelectRef} styles={styles} {...attributes} />
    ) : (
      <Select
        type="select"
        getOptionValue={getOptionValue}
        isDisabled={disabled}
        invalid={!!errorMessage}
        options={options}
        styles={styles}
        isMulti={isMulti}
        {...attributes}
        value={value?.label != 'unknown' && value}
      />
    )}
    {errorMessage ? (
      <span className="invalid-feedback invalid-feedback--customInput d-block">
        {errorMessage}
      </span>
    ) : null}
  </div>
)

CustomSelect.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  getSelectRef: PropTypes.object,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array,
  value: PropTypes.any,
  getOptionValue: PropTypes.func,
}

export default CustomSelect
