import { forwardRef } from 'react'
import classes from './Style.module.scss'
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap'

const CustomDropdownItem = forwardRef(
  ({ onClick, className, children, style }, ref) => (
    <span
      className={`${className}`}
      ref={ref}
      onClick={e => {
        onClick?.(e)
        e.stopPropagation()
      }}
      style={style}
    >
      {children}
    </span>
  ),
)

CustomDropdownItem.displayName = 'CustomItem'

CustomDropdownItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  style: PropTypes.object,
}

const DropdownItem = ({ children, onClick = () => {}, eventKey }) => {
  return (
    <Dropdown.Item
      as={CustomDropdownItem}
      className={classes.dropdownItem}
      onClick={onClick}
      eventKey={eventKey}
    >
      {children}
    </Dropdown.Item>
  )
}

DropdownItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
}

export default DropdownItem
