import { applySnapshot, getSnapshot, types } from 'mobx-state-tree'

import {
  ResourceTypeModel,
  RoleModel,
  CommonTagModel,
  DictionaryTemplateModel,
  DictionaryUserModel,
} from 'stores/models'
import { apiV1 } from 'core/requests'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'

const DictionaryStore = types
  .model('DictionaryStore')
  .props({
    _resourceTypeTemplates: types.optional(
      types.array(DictionaryTemplateModel),
      [],
    ),
    _roles: types.optional(types.array(RoleModel), []),
    _resourceTypes: types.optional(types.array(ResourceTypeModel), []),
    _tags: types.optional(types.array(CommonTagModel), []),
    _users: types.optional(types.array(DictionaryUserModel), []),
    _isLoading: types.optional(types.boolean, false),
    _isLoadingDetail: types.optional(types.boolean, false),
  })
  .actions(self => {
    const DICTIONARY_API = '/api/dictionary'

    const setTemplates = templateData => {
      applySnapshot(self._resourceTypeTemplates, templateData)
    }

    const setRoles = rolesData => {
      applySnapshot(self._roles, rolesData)
    }

    const setResourceTypes = typesData => {
      applySnapshot(self._resourceTypes, typesData)
    }

    const setTags = tagsData => {
      applySnapshot(self._tags, tagsData)
    }

    const setUsers = usersData => {
      applySnapshot(self._users, usersData)
    }

    const setIsLoading = isLoading => {
      self._isLoading = isLoading
    }

    const setIsLoadingDetail = isLoading => {
      self._isLoadingDetail = isLoading
    }

    const getResourceTypeTemplates = async () => {
      const templateListRequest = fromPromise(
        apiV1.get(`${DICTIONARY_API}/templates`),
      )

      when(() =>
        templateListRequest.case({
          fulfilled: response => {
            setTemplates(response.data)
            return true
          },
        }),
      )
      return templateListRequest
    }

    const getUsers = async () => {
      const usersListRequest = fromPromise(apiV1.get(`${DICTIONARY_API}/users`))

      when(() =>
        usersListRequest.case({
          fulfilled: response => {
            setUsers(response.data)
            return true
          },
        }),
      )
      return usersListRequest
    }

    const getRoles = async () => {
      const rolesListRequest = fromPromise(apiV1.get(`${DICTIONARY_API}/roles`))

      when(() =>
        rolesListRequest.case({
          fulfilled: response => {
            setRoles(response.data)
            return true
          },
        }),
      )
      return rolesListRequest
    }

    const getResourceTypes = async () => {
      const resourceTypesList = fromPromise(
        apiV1.get(`${DICTIONARY_API}/resource-type`),
      )

      when(() =>
        resourceTypesList.case({
          fulfilled: response => {
            setResourceTypes(response.data)
            return true
          },
        }),
      )
      return resourceTypesList
    }

    const getTags = async params => {
      const tagsListRequest = fromPromise(
        apiV1.get(`${DICTIONARY_API}/tags`, { params }),
      )

      when(() =>
        tagsListRequest.case({
          fulfilled: response => {
            setTags(response.data)
            return true
          },
        }),
      )
      return tagsListRequest
    }

    return {
      getResourceTypeTemplates,
      getRoles,
      getTags,
      getResourceTypes,
      setIsLoading,
      getUsers,
      setIsLoadingDetail,
    }
  })
  .views(self => ({
    get typeTemplates() {
      return getSnapshot(self._resourceTypeTemplates)
    },
    get resourceTypeTemplates() {
      return [
        ...this.typeTemplates,
        { id: 8, name: 'ResourceName' },
        { id: 9, name: 'ResourceDescription' },
      ]
    },
    get users() {
      return getSnapshot(self._users)
    },
    get roles() {
      return getSnapshot(self._roles)
    },
    get resourceTypes() {
      return getSnapshot(self._resourceTypes)
    },
    get tags() {
      return getSnapshot(self._tags)
    },
    get isLoading() {
      return self._isLoading
    },
    get isLoadingDetail() {
      return self._isLoadingDetail
    },
  }))

export default DictionaryStore
