import React, { useEffect } from 'react'
import { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import { ChevronDownOutline } from 'react-ionicons'
import './Faq.scss'
import { FAQ_FOR_ADMIN, FAQ_FOR_MODERATOR, FAQ_FOR_USER } from './consts'
import { useStore } from 'hooks'

function Faq() {
  const [faqs, setFaqs] = useState([])
  const { user } = useStore('authStore')

  const bgAccordion = {
    background: '#fff',
    borderRadius: '2px',
    boxShadow: 'none',
    padding: '20px',
    color: '#333',
    border: 'none',
  }

  const borderCollapse = {
    borderRadius: '0 0 12px 12px',
    background: '#fff',
  }

  const toggleFaq = id => {
    setFaqs(
      faqs.map(faq => {
        if (faq.id == id) {
          faq.open = !faq.open
        } else {
          faq.open = false
        }

        return faq
      }),
    )
  }

  useEffect(() => {
    switch (user.role) {
      case 'Moderator':
        setFaqs(FAQ_FOR_MODERATOR)
        break
      case 'User':
        setFaqs(FAQ_FOR_USER)
        break
      case 'Administrator':
        setFaqs(FAQ_FOR_ADMIN)
        break
    }
  }, [user])

  return (
    <Accordion>
      {faqs?.map(item => (
        <div key={item.id}>
          <Accordion.Toggle
            as={Button}
            style={bgAccordion}
            className="d-flex justify-content-between px-2 bgAccordion"
            eventKey={`${item.id}`}
            block
            onClick={() => toggleFaq(item.id)}
          >
            <p className="p-0 m-0 pl-3 font-weight-bold w-100 text-left d-flex align-items-center justify-content-between">
              {item.title}
              <ChevronDownOutline
                style={{
                  color: '#6881E7',
                  cursor: 'pointer',
                  fontSize: '16px',
                  transform: `rotate(${item.open ? 180 : 0}deg)`,
                  transition: 'transform 0.3s ease',
                  verticalAlign: 'text-bottom',
                }}
                color={'#4A5567'}
              />
            </p>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={`${item.id}`} style={borderCollapse}>
            <ul>
              <li>
                <pre className="pt-2 pb-5 ml-4 desc">{item.body}</pre>
              </li>
            </ul>
          </Accordion.Collapse>
          <hr className="border-0 my-2" />
        </div>
      ))}
    </Accordion>
  )
}

export default Faq
