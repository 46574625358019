import { useEffect, useState } from 'react'
import Table from 'rc-table'
import 'react-resizable/css/styles.css'
import './ResizableTable.scss'
import ResizableHeaderCell from './ResizableHeaderCell'
import PropTypes from 'prop-types'

const getStoredColumnSizes = (storage, key) =>
  key ? JSON.parse(storage.getItem(key) || '[]') : []

const ResizableTable = ({
  columns,
  persistColumnsSizesKey,
  persistColumnsSizesStorage = localStorage,
  ...tableProps
}) => {
  const [tableColumns, setTableColumns] = useState([])
  useEffect(() => {
    setTableColumns(
      columns.map((column, index) => {
        const columnsSizes = getStoredColumnSizes(
          persistColumnsSizesStorage,
          persistColumnsSizesKey,
        )
        return {
          ...column,
          width: columnsSizes[index]?.width || column.width,
          height: columnsSizes[index]?.height || column.height,
          onHeaderCell: (columnData, columnIndex) => {
            const columnsSizes = getStoredColumnSizes(
              persistColumnsSizesStorage,
              persistColumnsSizesKey,
            )

            return {
              ...(column.onHeaderCell
                ? column.onHeaderCell(columnData, columnIndex)
                : {}),
              minConstraints: columnData.minConstraints,
              maxConstraints: columnData.maxConstraints,
              axis: columnData.axis,
              width: columnsSizes[index]?.width || columnData.width,
              height: columnsSizes[index]?.height || columnData.height,
              onResize: (event, { size }) => {
                if (persistColumnsSizesKey) {
                  const nextColumnsSizes = getStoredColumnSizes(
                    persistColumnsSizesStorage,
                    persistColumnsSizesKey,
                  )
                  nextColumnsSizes[index] = {
                    width: size.width,
                    height: size.height,
                  }
                  persistColumnsSizesStorage.setItem(
                    persistColumnsSizesKey,
                    JSON.stringify(nextColumnsSizes),
                  )
                }
                setTableColumns(prevColumns => {
                  const nextColumns = [...prevColumns]
                  nextColumns[index] = {
                    ...prevColumns[index],
                    width: size.width,
                    height: size.height,
                  }
                  return nextColumns
                })
              },
            }
          },
        }
      }),
    )
  }, [columns, persistColumnsSizesKey, persistColumnsSizesStorage])

  return (
    <Table
      {...tableProps}
      components={{
        ...tableProps.components,
        header: {
          ...tableProps.components?.header,
          cell: ResizableHeaderCell,
        },
      }}
      columns={tableColumns}
    />
  )
}

ResizableTable.propTypes = {
  columns: PropTypes.array,
  persistColumnsSizesKey: PropTypes.string,
  persistColumnsSizesStorage: PropTypes.string,
}

export default ResizableTable
