import { useDropzone } from 'react-dropzone'
import { useCallback } from 'react'
import classes from './Style.module.scss'
import { FileTrayOutline, TrashOutline } from 'react-ionicons'
import { Button } from 'components'
import { FormattedMessage } from 'react-intl'
import { Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'

function dropzoneValidation(file) {
  const { type } = file
  if (
    type.includes('image') &&
    type !== 'image/jpeg' &&
    type !== 'image/jpg' &&
    type !== 'image/png' &&
    type !== 'image/svg'
  ) {
    return {
      code: 'dropzone.image.validation',
      message: `Разрешенные типы: .png, .jpg, .jpeg, .svg`,
    }
  }

  return null
}

const UploadDropzone = ({ type, onChange, files, disabled, label }) => {
  const onDrop = useCallback(
    acceptedFiles => {
      onChange([
        ...files,
        ...acceptedFiles.map(file =>
          file.type.includes('image')
            ? Object.assign(file, {
                preview: URL.createObjectURL(file),
                id: Date.now() + Math.random(),
              })
            : file,
        ),
      ])
    },
    [onChange, files],
  )
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept:
      type === 'image'
        ? 'image/*'
        : 'image/*, video/mp4, video/webm, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .ogg',
    onDrop,
    disabled,
    validator: file => dropzoneValidation(file),
  })

  const onDelete = useCallback(
    file => {
      onChange(files.filter(el => el.id !== file.id))
    },
    [onChange, files],
  )

  const thumbs = files.map(file => {
    return (
      <div className={classes.thumb} key={file.name + file.preview}>
        <div className={classes.thumbInner}>
          {file.type.includes('image') ? (
            <img alt={file.name} src={file.preview} classes={classes.img} />
          ) : (
            <div className="d-flex justify-content-between align-items-center flex-column w-100">
              <div className="mock-preview">Превью будет позже...</div>
              <p>{file.name}</p>
            </div>
          )}
        </div>
        <div className={classes.thumbBackdrop}>
          <div className={classes.thumbIcon}>
            <TrashOutline
              className={classes.thumbClose}
              onClick={() => onDelete(file)}
              width="18px"
              height="18px"
              color={'#fff'}
            />
          </div>
        </div>
      </div>
    )
  })

  return (
    <div>
      <>
        {label && <div className={classes.mainLabel}>{label}</div>}
        <div
          className={[
            !disabled && classes.dropzone,
            files.length
              ? 'justify-content-between'
              : 'align-items-center justify-content-center',
          ].join(' ')}
        >
          {files.length ? (
            <>
              <aside className={classes.thumbsContainer}>{thumbs}</aside>
              {!disabled && (
                <label htmlFor="dropzone" className={classes.dropzoneLabel}>
                  <FormattedMessage
                    id={
                      type === 'image'
                        ? 'news.form.dropzone.add.image.label'
                        : 'news.form.dropzone.add.any.files.label'
                    }
                  />
                  <input {...getInputProps()} id="dropzone" />
                </label>
              )}
            </>
          ) : !disabled ? (
            type === 'image' ? (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Row>
                  <Col className="text-center">
                    <FileTrayOutline
                      color="#dadada"
                      width="40px"
                      height="40px"
                    />
                    <Button
                      variant="outline-primary"
                      className="btn mb-3 mt-2 ml-auto mr-auto"
                    >
                      <FormattedMessage id="global.upload" />
                    </Button>
                    <div className={classes.dropzoneDescription}>
                      <p>
                        <FormattedMessage id="news.form.dropzone.image" />
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Row>
                  <Col className="text-center">
                    <FileTrayOutline
                      color="#dadada"
                      width="40px"
                      height="40px"
                    />
                    <Button
                      variant="outline-primary"
                      className="btn mb-3 mt-2 ml-auto mr-auto"
                    >
                      <FormattedMessage id="global.upload" />
                    </Button>
                    <div className={classes.dropzoneDescription}>
                      <p>
                        <FormattedMessage id="news.form.dropzone.any.files.1" />
                      </p>
                      <p>
                        <FormattedMessage id="news.form.dropzone.any.files.2" />
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          ) : null}
        </div>
      </>
      {fileRejections.length ? (
        <div className="invalid-feedback d-block">
          <FormattedMessage
            id={fileRejections[0].errors[0].code}
            defaultMessage={fileRejections[0].errors[0].message}
          />
        </div>
      ) : null}
    </div>
  )
}

UploadDropzone.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  files: PropTypes.array,
  disabled: PropTypes.bool,
  label: PropTypes.string,
}

export default UploadDropzone
