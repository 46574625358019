import { types } from 'mobx-state-tree'
import mainPageNewsModel from './mainPageNewsModel'
import mainPageResourceModal from './mainPageResourceModel'
import mainPageTagModel from './mainPageTagModel'

const mainPageDataModel = types.model('MainPageDataModel', {
  newResources: types.optional(types.array(mainPageResourceModal), []),
  downloadedResources: types.optional(types.array(mainPageResourceModal), []),
  viewedResources: types.optional(types.array(mainPageResourceModal), []),
  commentedResources: types.optional(types.array(mainPageResourceModal), []),
  userActivedResources: types.optional(types.array(mainPageResourceModal), []),
  populatedTags: types.optional(types.array(mainPageTagModel), []),
  populatedUserTags: types.optional(types.array(mainPageTagModel), []),
  news: types.optional(types.array(mainPageNewsModel), []),
  newTags: types.optional(types.array(mainPageTagModel), []),
})

export default mainPageDataModel
