import { types } from 'mobx-state-tree'

const CommonFileModel = types.model('CommonFileModel', {
  id: types.optional(types.integer, 0),
  name: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  isMain: types.optional(types.boolean, false),
  smallCrop: types.maybeNull(types.string),
  mediumCrop: types.maybeNull(types.string),
  fileLocation: types.maybeNull(types.string),
  largeCrop: types.maybeNull(types.string),
})

export default CommonFileModel
